import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Event from "../Event/Event";
import SectionHeading from "../Global/SectionHeading";
import { Link } from "react-router-dom";
import useStore from "../../GlobalProvider/useStore";

const Events = () => {
  const { allEvents } = useStore();
  return (
    <section className="events-section section-padding">
      <Container>
        <Row>
          <Col md={12}>
            <SectionHeading topTitle={"পরবর্তী ইভেন্ট"} desc={"গৃহত্যাগীর সাথে হোক আপনার সমুদ্র, পাহাড় কিংবা ঝর্না বিচরণ"} />
          </Col>
        </Row>
        <Row className="justify-content-center">
          {allEvents?.slice(0, 6).map((event, i) => (
            <Col key={i} lg={4} md={6}>
              <Event data={event} />
            </Col>
          ))}
        </Row>
        <Row className="mt-4">
          <Col className="text-center">
            <Link to="/events" className="button">সবগুলো ইভেন্ট দেখুন</Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Events;

import { useRef } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import emailjs from "emailjs-com";
import './ContactForm.css';
import { toast } from 'react-toastify';
const ContactForm = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        const contactTost = toast.loading('Please Wait...', {
            position: "bottom-right",
        });
        // Validation
        const name = form.current.elements.name.value;
        const subject = form.current.elements.subject.value;
        const phone = form.current.elements.phone.value;
        const email = form.current.elements.email.value;
        const message = form.current.elements.message.value;

        if (!name || !subject || !phone || !email || !message) {
            toast.update(contactTost, {
                render: 'All fields are required!', 
                type: "error",
                isLoading: false,
                closeOnClick: true,
                autoClose: 5000
            });
            return;
        }
        if (!/^[a-zA-Z\s]+$/.test(name)) {
            toast.update(contactTost, {
                render: 'Name should only contain letters!', 
                type: "error",
                isLoading: false,
                closeOnClick: true,
                autoClose: 5000
            });
            return;
        }
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            toast.update(contactTost, {
                render: 'Invalid email!', 
                type: "error",
                isLoading: false,
                closeOnClick: true,
                autoClose: 5000
            });
            return;
        }
        if (!/^\d{11}$/.test(phone)) {
            toast.update(contactTost, {
                render: 'Phone number should only contain 11 digits!', 
                type: "error",
                isLoading: false,
                closeOnClick: true,
                autoClose: 5000
            });
            return;
        }
        // rest of the code to send the email
        emailjs
          .sendForm(
            "service_8a4p2hf",
            "template_lxv2b9o",
            form.current,
            "user_q8tg9dH3vgOsd8fVKbLzg"
          )
          .then(
            (result) => {
                toast.update(contactTost, {
                    render: 'Your message has send successfully...', 
                    type: "success",
                    isLoading: false,
                    closeOnClick: true
                });
                form.current.reset();
            },
            (error) => {
                toast.update(contactTost, {
                    render: error.text, 
                    type: "error",
                    isLoading: false,
                    closeOnClick: true
                });
            }
        );
    };
    
    return (
        <form ref={form} className='contact-form-area p-3 p-md-5' onSubmit={sendEmail}>
            <div className="form-head mb-4">
                <h3 className='script-heading'>Contact us</h3>
                <h2>Send a message</h2>
            </div>
            <FloatingLabel controlId="floatingName" label="Your Name" className="mb-3">
                <Form.Control name='name' type="text" placeholder='Your Name' />
            </FloatingLabel>
            <FloatingLabel controlId="floatingSubject" label="Subject" className="mb-3">
                <Form.Control name='subject' type="text" placeholder="Subject" />
            </FloatingLabel>
            <Row>
                <Col md={6}>
                    <FloatingLabel controlId="floatingPhone" label="Phone" className="mb-3">
                        <Form.Control name='phone' type="tel" placeholder="Phone" />
                    </FloatingLabel>
                </Col>
                <Col md={6}>
                    <FloatingLabel controlId="floatingEmail" label="Email" className="mb-3">
                        <Form.Control name='email' type="email" placeholder="Email" />
                    </FloatingLabel>
                </Col>
            </Row>
            <FloatingLabel controlId="floatingMessage" label="Message" className="mb-3">
                <Form.Control name='message' as="textarea" placeholder="Leave a comment here" />
            </FloatingLabel>
            <button type="submit" className='button button-main'>Send Message</button>
        </form>
        
    );
};

export default ContactForm;
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BoundingBox, Facebook, Instagram } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import paymentMethod from '../../assets/payment-method.webp';
import logo from '../../assets/logo-white.webp';
import './Footer.css';

const Footer = () => {
    return (
        <>
        <footer className="footer-area section-padding">
            <Container>
                <Row>
                    <Col lg={3} md={12}>
                        <img src={logo} alt="Grihotagi" style={{width: '100%', maxWidth: '150px'}} />
                        <ul className="list-unstyled list-inline list-social mb-6 mb-md-0 mt-5">
                            <li className="list-inline-item list-social-item me-3">
                                <a href="https://www.facebook.com/Grihotagibd" className="text-decoration-none" target='_blank' rel="noreferrer" title='Like us on facebook'>
                                    <Facebook color="#FFFFFF" size={25} />
                                </a>
                            </li>
                            <li className="list-inline-item list-social-item me-3">
                                <a href="https://www.facebook.com/groups/Grihotagi" className="text-decoration-none" target='_blank' rel="noreferrer" title='Join our facebook group'>
                                    <BoundingBox color="#FFFFFF" size={25} />
                                </a>
                            </li>
                            <li className="list-inline-item list-social-item me-3">
                                <a href="https://www.instagram.com/grihotagibd/" className="text-decoration-none" target='_blank' rel="noreferrer" title='Follow us on instagram'>
                                    <Instagram color="#FFFFFF" size={25} />
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col lg={3} md={6} xs={6} className="pt-4">
                        <h6 className="fw-bold text-uppercase mb-4">Usefull Links</h6>
                        <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                            <li className="mb-2"><Link to="events">Events</Link></li>
                            <li className="mb-2"><Link to="about-us">About Us</Link></li>
                            <li className="mb-2"><Link to="hotels">Our Hotels</Link></li>
                            <li className="mb-2"><Link to="blog">News and Blogs</Link></li>
                            <li><Link to="contact-us">Contact Us</Link></li>
                        </ul>
                    </Col>
                    <Col lg={3} md={6} xs={6} className="pt-4">
                        <h6 className="fw-bold text-uppercase mb-4">Usefull Links</h6>
                        <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                            <li className="mb-2"><Link to="privacy-policy">Privacy Policy</Link></li>
                            <li className="mb-2"><Link to="terms-and-conditions">Terms & Conditions</Link></li>
                        </ul>
                    </Col>
                    <Col lg={3} md={12} className="pt-4">
                        <div className="footer-payment">
                            <h6 className="fw-bold text-uppercase mb-4">Payment Method</h6>
                            <img src={paymentMethod} alt="payment method | Grihotagi" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
         <div className="footer-area section-padding" style={{padding: '10px 0px 10px 0px', borderTop: '1px solid rgba(var(--mct),0.2)'}}>
            <Container>
                <Row>
                    <Col md={6}><p className="pt-0 pt-md-0 text-white text-md-start text-center">&copy; 2022 | All Right Resirved.</p></Col>
                    <Col md={6}><p className="pt-2 pt-md-0 text-white text-md-end text-center">Developed By: <a href="http://llcgteam.com" className='text-white' target="_blank" rel="noopener noreferrer">LLCG Team</a></p></Col>
                </Row>
            </Container>
         </div>
         </>
    );
};

export default Footer;
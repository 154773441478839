import React from 'react';
import { Card, Table } from 'react-bootstrap';
import useStore from '../../GlobalProvider/useStore';
import jwt_decode from "jwt-decode";
import { Link } from 'react-router-dom';
import { Eye } from 'react-bootstrap-icons';
const Tours = () => {
    const {allBookings,authUser} = useStore();
    const {email} = jwt_decode(authUser);
    const myBooking = allBookings.filter(booking => booking.email === email);
    return (
        <div>
            <Card className='shadow border-top-0 rounded-0 shadow-sm'>
                <Card.Body className='p-4'>
                    <Table striped responsive='md'>
                        <thead>
                            <tr>
                                <th>*</th>
                                <th>Package name</th>
                                <th>Booking Date</th>
                                <th>Status</th>
                                <th className='text-end'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                myBooking.reverse().map((booking,i)=><tr key={i}>
                                <td>{i+1}</td>
                                <td>{booking.pack_name}</td>
                                <td>{booking.booking_date}</td>
                                <td className='text-capitalize'>{booking.status}</td>
                                <td className='text-end'><Link to={`/events/single-event/${booking.event_id}`} className='btn btn-outline-success btn-sm rounded-circle'><Eye /></Link></td>
                            </tr>)
                            }
                            
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Tours;
import React, { useEffect, useRef, useState } from 'react';
import useStore from '../../GlobalProvider/useStore';
import jwt_decode from "jwt-decode";
import { Link, Navigate } from 'react-router-dom';
import { Card, FloatingLabel, Form, Table,Col,Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from 'react-toastify';
import { Eye, Pencil } from 'react-bootstrap-icons';
const MyBlog = () => {
    const { register, handleSubmit } = useForm();
    const {allBlogs,authUser,updateBlog,setUpdateBlog, callBlogs, setCallllBlogs} = useStore()
    const loginUser = jwt_decode(authUser);
    const [updatableBlog,setUpdatableBlog] = useState({})
    const [uploadedImage,setUploadedImage] = useState()
    const [id,setId] = useState(null)
    const editorRef = useRef(null);
    const selectedBlogs = allBlogs.filter(blog => blog.email === loginUser.email)
    useEffect(()=>{
        const updateBlog = selectedBlogs.find(blog => blog.blog_id === +id)
        setUpdatableBlog(updateBlog)
    },[id,selectedBlogs])
    if(authUser === null){
        return <Navigate to="/login" replace={true} /> ;
    }
    const log = (data) => {
      if (editorRef.current) {
        return data.texts = (editorRef.current.getContent());
      }
    };
    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          setUploadedImage(e.target.files[0]);
        }
      };
    
    
    const onsubmit = data =>{
        log(data)
        if (data.title === '') {
            data.title = updatableBlog?.title
        }
      
        // console.log(updatableBlog?.blog_img)
        data.author = updatableBlog?.author
        data.date = updatableBlog?.date
        data.author_img = updatableBlog.author_img
        data.role = updatableBlog?.role
        data.email = updatableBlog?.email
        let formData = new FormData()
        formData.append('blog_img',uploadedImage)
        data.blog_img = updatableBlog?.blog_img.includes("_") ? updatableBlog?.blog_img.split("_")[1] : updatableBlog?.blog_img
        formData.append('data',JSON.stringify(data))
        const updateTost = toast.loading('Please Wait...', {
            position: "bottom-right",
        });
        fetch(`${process.env.REACT_APP_BASE_URL}/blog/update/${id}`, {
            method: "PATCH",
            body: formData,
        }).then(res => res.json())
        .then(data => {
            if (data.code === 200) {
                toast.update(updateTost, {
                    render: 'Post Updated.', 
                    type: "success",
                    isLoading: false,
                    closeOnClick: true,
                    autoClose: 5000
                });
                setCallllBlogs(callBlogs);
                setUpdateBlog(false);
                setId(null)
            }
        }).catch((error) => {
            const errorMessage = error.message;
            toast.update(updateTost, {
                render: errorMessage, 
                type: "error",
                isLoading: false,
                closeOnClick: true,
            });
        });
      }
    return (
        <div>
            {
                updateBlog ? <div>
                    <div className="py-3">
                    <Card className="shadow border-top-0 rounded-0 shadow-sm">
                        <Card.Body className="p-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <h2 className="mb-4">Update post</h2>
                            {/* <span>{selectedBlog.currentDate}</span> */}
                        </div>
                        <form onSubmit={handleSubmit(onsubmit)}>
                        <Row className="mt-4 mb-4">
                            <Col md={6}>
                            <FloatingLabel
                                controlId="floatingTitle"
                                label="Title"
                                className="mb-3"
                            >
                                <Form.Control
                                defaultValue={updatableBlog?.title}
                                type="text"
                                {...register("title")}
                                placeholder="Title"
                                required
                                />
                            </FloatingLabel>
                            </Col>
                            <Col md={6} className='d-flex gap-2'>
                                <img src={`${process.env.REACT_APP_Blog_IMG}/${updatableBlog?.blog_img ? (updatableBlog.blog_img.includes("_") ? updatableBlog.blog_img.split("_")[1] : updatableBlog.blog_img) : 'uploadedImage'}`} style={{height: '56px', objectFit: 'contain'}} alt='My blog img' />
                            <FloatingLabel
                                controlId="floatingTitle"
                                label="Blog Image"
                                className="mb-3"
                            >
                                <Form.Control
                                type="file"
                                onChange={imageChange}
                                // {...register("blog_img")}
                                placeholder="blog image"
                                />
                            </FloatingLabel>
                            </Col>
                        </Row>
                        <Editor
                            apiKey={process.env.REACT_APP_EDITOR_KEY}
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            initialValue={updatableBlog?.texts}
                            init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "code",
                                "help",
                                "wordcount",
                            ],
                            toolbar:
                                "undo redo | blocks | " +
                                "bold italic forecolor media | alignleft aligncenter " +
                                "alignright bullist numlist outdent indent removeformat",
                            content_style:
                                "body { font-family: -apple-system, BlinkMacSystemFont, Spectral, Georgia, serif; font-size:16px }",
                            }}
                        />
                        <button className="button button-main mt-4">Update Post</button>
                        <button className="button mt-4" onClick={()=>setUpdateBlog(false)}>Cancel</button>
                        </form>
                        </Card.Body>
                    </Card>
                    </div>
                </div> : 
            
            <Table striped responsive='md'>
                <thead>
                    <tr>
                        <th>*</th>
                        <th>Title</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        selectedBlogs.map((blog,i)=><tr key={i}>
                        <td>{i+1}</td>
                        <td>{blog.title.slice(0,10)}...</td>
                        <td>{blog.date}</td>
                        <td className='text-capitalize'>{blog.status}</td>
                        <td>
                            <Link to={`/blog/${blog.blog_id}`} title='View This Post' className="btn btn-outline-success btn-sm me-1 rounded-circle"><Eye /></Link>
                            <button onClick={()=>{setUpdateBlog(true);setId(blog.blog_id)}} title='Edit This Post' className="btn btn-outline-warning btn-sm me-1 rounded-circle"><Pencil /></button>
                        </td>
                    </tr>)
                    }
                </tbody>
            </Table>}
        </div>
    );
};

export default MyBlog;
import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import InnerBanner from '../../component/InnerBanner/InnerBanner';

const Terms = () => {
    return (
        <>
            <Helmet>
                <title>Terms & Conditions | Grihotagi</title>
            </Helmet>
           <InnerBanner title='শর্তাবলী' desc='গৃহত্যাগী দ্বারা প্রদত্ত যেকোনও পরিষেবা ব্যবহার করার আগে দয়া করে নিয়ম ও শর্তাবলী সাবধানে পড়ুন।' /> 
           <section className="contact-us-section section-padding">
                <Container>
                    <div className="main-content-area">
                        <p>বুকিং করার পরে নির্দিষ্ট শর্তে ও পদ্ধতিতে বুকিং বাতিল করা যাবে।</p>
                        <p>বুকিং করার পরে ট্যুরে অংশগ্রহণ না করতে পারলে বুকিং বাতিলের জন্য ওয়েবসাইডে দেয়া Contact us এর ফরমটি ফিলাপ করতে হবে। ফরমে নাম (বুকিংয়ে অনুযায়ী), সাবজেক্ট (বুকিং বাতিল), ফোন নম্বর (বুকিংয়ে যেটা দেয়া হয়েছে), ইমেইল (বুকিংয়ে যেটা দেয়া হয়েছে) উল্লেখ করতে হবে। মেসেজ বক্সে বুকিং ক্যান্সেলের কারণ, যে ট্যুরের ( ট্যুরের তারিখসহ) বুকিং দিয়েছিলেন, আপনাকে টাকা পাঠানোর মাধ্যম লিখতে হবে। সাবমিট করার ৮ ঘন্টার মধ্যে আপনাকে ফিডব্যাক না জানালো হলে আমাদের ফোন নম্বরে যোগাযোগ করতে  হবে।</p>
                        <p>এক ট্যুরের জন্য বুকিং করে আপনি ট্যুরে যেতে না পারলে বুকিংমানি অন্য ট্যুরে ট্রান্সফার করা যাবে না।</p>
                        <p>ট্যুরের জার্নি তারিখের ১০ দিন আগে বুকিং ক্যান্সেল করলে বুকিংকৃত পুরো টাকা রিফান্ড করা হবে। ৫ দিন আগে ক্যান্সেল করলে বুকিংকৃত টাকার ৫০% রিফান্ড করা হবে।</p>
                        <p>টাকা রিফান্ড : ট্যুরের জার্নি তারিখের ৭ দিনের মধ্যে আপনার টাকা রিফান্ড করা হবে।</p>
                        <p>জার্নি তারিখের ৫ দিনের কম সময়ে ক্যান্সেল করলে বুকিংকৃত টাকা রিফান্ডের সুযোগ নেই। তবে এ ক্ষেত্রে আপনার পরিবর্তে অন্যকাউকে ট্যুরে পাঠাতে পারবেন। তবে অবশ্যই ট্যুরের জার্নি সময়ের ৬ ঘণ্টা আগে আমাদের জানাতে হবে।</p>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default Terms;
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Calendar2, Wallet2, Umbrella, Clock } from 'react-bootstrap-icons';
import LazyLoad from 'react-lazy-load';
import { Link } from 'react-router-dom';
import './Event.css';

const Event = (props) => {
    const {id,event_name,price,start_date,images,type,duration,seat,booked} = props?.data || {};
    const allImages = images?.split('_');

    const [dateOverMsg, setDateOverMsg] = useState('');
    useEffect(()=>{
        // Create Date object for event_date
        const eventDate = new Date(start_date);
      
        // Compare event_date to today's date
        if (eventDate < new Date()) {
          setDateOverMsg('সম্পুর্ন হয়েছে');
        }
    },[start_date])
    
    return (
        <LazyLoad height={370}  offset={200} threshold={0.95}>
            <Link to={`/events/single-event/${id}`} className={dateOverMsg? 'date-over' : 'tour-running'}>
                <figure className="single-event">
                    {
                        allImages?.length > 0 && <img src={`${process.env.REACT_APP_SLIDER_IMG}/${allImages[1]}`} alt={event_name} />
                    }
                    <figcaption>
                        <h2>{event_name}</h2>
                        <div>
                            <p><Umbrella className='align-center me-1' size={16} style={{marginTop: '-2px'}} /> {type}</p>
                            <p><Clock className='align-center me-1' size={16} style={{marginTop: '-2px'}} /> {duration}</p>
                            <p><Clock className='align-center me-1' size={16} style={{marginTop: '-2px'}} /> {seat - booked} Seat Available.</p>
                        </div>
                        <div className='single-event-bottom'>
                            <Row>
                                <Col className='text-start'><span><Wallet2 className='align-center me-1' size={20} style={{marginTop: '-5px'}} /> ৳{price}</span></Col>
                                <Col className='text-end'><span><Calendar2 className='align-center me-1' size={20} style={{marginTop: '-5px'}} /> {dateOverMsg? <span style={{color: 'green'}}>{dateOverMsg}</span> : start_date}</span></Col>
                            </Row>
                        </div>
                    </figcaption>
                </figure>
            </Link>
        </LazyLoad>
    );
};

export default Event;
import { React, useState } from 'react';
import { Container, Row, Col, Carousel, Accordion } from 'react-bootstrap';
import InnerBanner from '../../component/InnerBanner/InnerBanner';
import './SingleEventPage.css';
import { CalendarEvent, Clock, EmojiWink, GeoAlt, People, PersonX, Truck } from 'react-bootstrap-icons';
import EventBook from './EventBook';
import GetUserInfo from './GetUserInfo';
import { Link, useParams } from 'react-router-dom';
import useStore from '../../GlobalProvider/useStore';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet-async';
const SingleEventPage = () => {
    const {allEvents} = useStore()
    const {id} = useParams()
    const [adult, setAdult] = useState(1);
    const [child, setChild] = useState(0);
    const [couple, setCouple] = useState(false);
    const [pay, setPay] = useState('Full Payment');
    const [paymentMethod, setPaymentMethod] = useState('Bkash');
    const [fee, setFee] = useState(0);
    const [clickBookNow, setclickBookNow] = useState(false);
    const selectedEvent = allEvents.find(event => +event.id === +id);
    const allImages = selectedEvent?.images?.split('_');
    const defaultPrice = parseInt(selectedEvent?.price);
    const [price, setPrice] = useState(defaultPrice);
    const [totalPrice, setTotalPrice] = useState(0);
    const [tnxid, setTnxId] = useState('');
    const couplePrice = parseInt(selectedEvent?.couple_extra);
    const bookingMoney = parseInt(selectedEvent?.booking_money);
    const childPrice = parseInt(selectedEvent?.child_price);
    if (!allEvents?.length || !selectedEvent) {
      return <span>loading</span>  
    }
    

    return (
        <>
            <Helmet>
                <title>{selectedEvent?.event_name} | Grihotagi</title>
            </Helmet>
            <InnerBanner title={selectedEvent?.event_name} />
            <section className="inner-event-page-area section-padding">
                <Container>
                    <Row className='mb-5'>
                        <Col md={12}>
                            <Carousel className='inner-event-slider' fade>
                                {
                                    allImages?.length > 0 && allImages?.slice(1,allImages?.length).map((img,i)=><Carousel.Item key={i} style={{backgroundImage: `url(${process.env.REACT_APP_SLIDER_IMG}/${img})` }} className='inner-event-carousel-item'></Carousel.Item>)
                                }
                            </Carousel>
                            <div className="inner-event-heading p-5">
                                <h2>{selectedEvent?.event_name}</h2>
                                <p><GeoAlt size={18} />{selectedEvent?.location}</p>
                                <p>{selectedEvent?.booked}/{selectedEvent?.seat} seat booked</p>
                                <p>Available {parseInt(selectedEvent?.seat) - parseInt(selectedEvent?.booked)}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {clickBookNow === false ? 
                            <Col lg={8}>
                                <div className="content-area">
                                    
                                    <Row className="tour-info mb-4">
                                        <Col md={4} sm={6} className='d-flex mb-3 align-center'>
                                            <div className="flex-shrink-0 light-icon">
                                                <Clock color='rgba(var(--mc))' size={20} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5>Duration</h5>
                                                <p>{selectedEvent?.duration}</p>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6} className='d-flex mb-3 align-center'>
                                            <div className="flex-shrink-0 light-icon">
                                                <People color='rgba(var(--mc))' size={20} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5>Group Size</h5>
                                                <p>{selectedEvent?.seat} Pepole</p>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6} className='d-flex mb-3 align-center'>
                                            <div className="flex-shrink-0 light-icon">
                                                <EmojiWink color='rgba(var(--mc))' size={20} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5>Tour Type</h5>
                                                <p>{selectedEvent?.type}</p>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6} className='d-flex mb-3 align-center mb-md-0'>
                                            <div className="flex-shrink-0 light-icon">
                                                <CalendarEvent color='rgba(var(--mc))' size={20} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5>Journy Date</h5>
                                                <p>{selectedEvent?.start_date}</p>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6} className='d-flex mb-3 align-center mb-md-0'>
                                            <div className="flex-shrink-0 light-icon">
                                                <PersonX color='rgba(var(--mc))' size={20} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5>Minimum Age</h5>
                                                <p>{selectedEvent?.min_age} Years</p>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6} className='d-flex mb-3 align-center mb-md-0'>
                                            <div className="flex-shrink-0 light-icon">
                                                <Truck color='rgba(var(--mc))' size={20} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5>Pickup From</h5>
                                                <p>{selectedEvent?.pick_up}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='main-content-area'>
                                        {parse(selectedEvent?.plan)}
                                    </div>
                                    <h2>যেসব প্রশ্ন প্রায়শই করা হয়ে থাকে</h2>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>অনলাইনে বুকিং করার পরে Approve না হলে কি করতে হবে?</Accordion.Header>
                                            <Accordion.Body>বুকিং কনফর্ম করে কনফর্মেশনের পেজটি স্ক্রিনশট আপনার সংগ্রহে রাখুন এবং বুকিং করার ৬ ঘণ্টার মধ্যে আপনার বুকিং Approved না করা হলে স্ক্রিনশটসহ আমাদের পেজে/নম্বরে যোগাযোগ করতে অনুরোধ রইলো।</Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>বুকিং করার পরে বুকিং ক্যান্সেল করা যাবে কিনা?</Accordion.Header>
                                            <Accordion.Body>বুকিং করার পরে নির্দিষ্ট শর্তে ও পদ্ধতিতে বুকিং বাতিল করা যাবে, <Link to='/terms-and-conditions'>বিস্তারিত</Link></Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>ট্যুরে বাসের সিট কিভাবে নির্ধারণ করা হয়?</Accordion.Header>
                                            <Accordion.Body>আমাদের সকল ট্যুরে বুকিং সিরিয়াল অনুযায়ী বাসের সিট, হোটেল/রিসোর্ট রুম নির্ধারণ করা হয়।</Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>ট্যুর প্যাকেজে কী কী থাকছে, আর কী কী থাকছে না?</Accordion.Header>
                                            <Accordion.Body>বুকিং করার আগে অবশ্যই ইভেন্ট ডিটেইলস ভালোভাবে পড়বেন। আপনি ট্যুর প্যাকেজে কী কী পাচ্ছেন, কী কী পাচ্ছেন না তা নিশ্চিত হয়েই বুকিং কনফর্ম করতে অনুরোধ রইলো।</Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>ট্যুর চলাকালীন কোনো সমস্যা হলে কি করতে হবে?</Accordion.Header>
                                            <Accordion.Body>হোস্ট/গাইডকে জানাতে হবে। এছাড়া আমাদের পেজ কিংবা নির্ধারিত নম্বরেও যোগাযোগ করতে পারেন।</Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>ট্যুরে মাদক সেবন করা যাবে কি না?</Accordion.Header>
                                            <Accordion.Body>কোনো ধরণের অবৈধ ও মাদক জাতীয় দ্রব্য বহন করা, সেবন করা যাবে না।</Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>
                                </div>
                            </Col>
                        : ''}
                        <Col lg={4} className='mt-lg-0 mt-5'>
                            <EventBook data={{adult,selectedEvent, setAdult, child, setChild, couple, setCouple, pay, setPay, paymentMethod, setPaymentMethod, fee, setFee, totalPrice, setTotalPrice, defaultPrice, price, setPrice, couplePrice, childPrice, bookingMoney, tnxid, setTnxId, clickBookNow, setclickBookNow}} />
                        </Col>
                        {clickBookNow && 
                            <Col lg={8} className='mt-lg-0 mt-5'>
                                <GetUserInfo data={setclickBookNow} />
                            </Col> 
                        }
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default SingleEventPage;
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Envelope, GeoAlt, Telephone } from 'react-bootstrap-icons';
import InnerBanner from '../../../component/InnerBanner/InnerBanner';
import contactImg from '../../../assets/contact-img.webp';
import './ContactPage.css';
import ContactForm from '../ContactForm/ContactForm';
import { Helmet } from 'react-helmet-async';

const ContactPage = () => {
    return (
        <>
            <Helmet>
                <title>Contuct Us | Grihotagi</title>
            </Helmet>
            <InnerBanner title='যোগাযোগ' />
            <section className="contact-us-section section-padding">
                <Container>
                    <Row className='mb-5'>
                        <Col lg={4}>
                            <div className="contact-info d-flex align-center mt-3 mt-lg-0">
                                <div className="flex-shrink-0 icon-wraper">
                                    <Telephone color='#FFFFFF' size={18} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <a href="tel:+8801648189538">+8801648-189538</a>
                                    <br />
                                    <a href="tel:+8801972953076">+8801972-953076</a>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="contact-info d-flex align-center mt-3 mt-lg-0">
                                <div className="flex-shrink-0 icon-wraper">
                                    <Envelope color='#FFFFFF' size={18} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <a href="mailto:grihotagi@gmail.com">grihotagi@gmail.com</a>
                                    <br />
                                    <a href="mailto:faysalbd9@gmail.com">faysalbd9@gmail.com</a>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="contact-info d-flex align-center mt-3 mt-lg-0">
                                <div className="flex-shrink-0 icon-wraper">
                                    <GeoAlt color='#FFFFFF' size={18} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <p>House: 48, Road: 12, Sector: 13 <br/>Uttara, Dhaka-1230</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-center'>
                        <Col lg={6}>
                            <img src={contactImg} alt="Contact | Grihotagi" />
                        </Col>
                        <Col lg={6}>
                            <ContactForm />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default ContactPage;
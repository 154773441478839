import React from "react";
import { Card, Table } from "react-bootstrap";
import { Check, Eye, Trash3, X } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useStore from "../../GlobalProvider/useStore";

const Blogs = () => {
    const {allBlogs, callBlogs, setCallllBlogs} = useStore();
    const navigate = useNavigate()
    const accept = (id) =>{
      const createTost = toast.loading('Please Wait...', {
        position: "bottom-right",
      });
      fetch(`${process.env.REACT_APP_BASE_URL}/blog/${id}?status=approve`,{
        method:'PATCH'
      }).then(res => res.json())
      .then(data => {
        if (data.code === 200) {
          toast.update(createTost, {
            render: "Blog Accepted Successfully!", 
            type: "success",
            isLoading: false,
            closeOnClick: true,
            autoClose: 5000
          });
          setCallllBlogs(!callBlogs)
        }
      }).catch((error) => {
        const errorMessage = error.message;
        toast.update(createTost, {
          render: errorMessage, 
          type: "error",
          isLoading: false,
          closeOnClick: true
        });
      });
    }
    const deny = (id) =>{
      const denyTost = toast.loading('Please Wait...', {
        position: "bottom-right",
      });
      fetch(`${process.env.REACT_APP_BASE_URL}/blog/${id}?status=declined`,{
        method:'PATCH'
      }).then(res => res.json())
      .then(data => {
        if (data.code === 200) {
          toast.update(denyTost, { 
            render: "Blog Denied!", 
            type: "success",
            isLoading: false,
            closeOnClick: true,
            autoClose: 5000
          });
          setCallllBlogs(!callBlogs)
        }
      }).catch((error) => {
        const errorMessage = error.message;
        toast.update(denyTost, {
          render: errorMessage, 
          type: "error",
          isLoading: false,
          closeOnClick: true
        });
      });
    }
    const removeBlog = (id,img) =>{
      const deleteTost = toast.loading('Please Wait......', {
        position: "bottom-right",
      });
      fetch(`${process.env.REACT_APP_BASE_URL}/blog/${id}`,{
        method:'DELETE',
        headers:{'content-type':'application/json'},
        body:JSON.stringify({img})
      }).then(res => res.json())
      .then(data => {
        if (data.code === 200) {
          toast.update(deleteTost, {
            render: 'Post Deleted!', 
            type: "success",
            isLoading: false,
            closeOnClick: true,
            autoClose: 5000
          });
          setCallllBlogs(!callBlogs)
        }
      }).catch((error) => {
        const errorMessage = error.message;
        toast.update(deleteTost, {
          render: errorMessage, 
          type: "error",
          isLoading: false,
          closeOnClick: true
        });
      });
    }
    const detailsBlog = (id) => {
      navigate(`/admin/blogUpdate/${id}`)
    }
  return (
    <Card className='shadow border-top-0 rounded-0 shadow-sm'>
      <Card.Body>
        <Table>
          <thead>
            <tr>
              <th>*</th>
              <th>Title</th>
              <th>Author</th>
              <th>Date</th>
              <th>Role</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allBlogs?.slice(0, allBlogs?.length).map((blog, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{blog?.title.slice(0,15)}...</td>
                <td><img style={{height:'30px',width:'30px',borderRadius:'15px'}} className="me-2" src={blog.author_img} alt="" />{blog?.author}</td>
                <td>{blog?.date}</td>
                <td className="text-capitalize">{blog?.role}</td>
                <td className="text-capitalize">{blog?.status}</td>
                <td>
                  {blog.status === 'declined' ? <button className="btn btn-outline-success btn-sm me-1 rounded-circle" onClick={()=>accept(blog.blog_id)} title='Accept'><Check /></button> : <button className="btn btn-outline-warning btn-sm me-1 rounded-circle" onClick={()=>deny(blog.blog_id)} title='Deny'><X /></button>}
                  <button className="btn btn-outline-danger btn-sm me-1 rounded-circle" onClick={()=>removeBlog(blog.blog_id,blog.blog_img)} title='Delete'><Trash3 /></button>
                  <button className="btn btn-outline-primary btn-sm rounded-circle" onClick={()=>detailsBlog(blog.blog_id)} title='Preview'><Eye /></button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default Blogs;

import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import InnerBanner from '../../component/InnerBanner/InnerBanner';

const Privacy = () => {
    return (
        <>
            <Helmet>
            <title>Privacy Policy | Grihotagi</title>
            </Helmet>
            <InnerBanner title='গোপনীয়তা নীতি' desc='আমরা আপনাকে আশ্বস্ত করতে চাই যে আমাদের ওয়েবসাইটে আপনার গোপনীয়তা রক্ষা করার ক্ষেত্রে আমরা উপযুক্ত মান অনুসরণ করি।' /> 
            <section className="contact-us-section section-padding">
                <Container>
                    <div className="main-content-area">
                        <p>আমাদের সাথে গ্রাহকের শেয়ার করা তথ্য আমরা সবসময়ই গোপন রাখি। গ্রাহককে চিহ্নিত করা যায় এমন তথ্য যেমন নাম, ঠিকানা, ফোন নম্বর, ইমেইল, ফেসবুক আইডি আমরা গোপন রাখি। আমরা আপনাকে আশ্বস্ত করছি, আমাদের ওয়েবসাইটে আপনার গোপনীয়তা রক্ষা করার ক্ষেত্রে আমরা উপযুক্ত মান অনুসরণ করি।</p>
                        <p>গৃহত্যাগী গ্রাহকের কোন তথ্য বিক্রি বা অন্যকোন সংস্থাকে সরবরাহ করে না</p>
                        <h2>গ্রাহক থেকে সংগৃহীত তথ্য যে কাজে ব্যবহার করা হয়</h2>
                        <ol>
                            <li>বুকিং করার পরে বুকিং কনফর্মেশনের জন্য</li>
                            <li>ভ্রমণের আগে নির্দেশনা জানাতে</li>
                            <li>ট্যুর চলাকালীন সময়ে ট্যুর গাইডের ট্যুর পরিচালনার স্বার্থে নির্দিষ্ট ট্যুর গাইডের কাছে (নাম ও নম্বর)</li>
                            <li>অপ্রীতিকর পরিস্থিতিতে সরকারি নিরাপত্তা সংস্থা।</li>
                        </ol>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default Privacy;
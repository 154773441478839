import React from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import reviewImg from '../../assets/reviews.webp';
import SectionHeading from '../../component/Global/SectionHeading';
import './Reviews.css';


const Reviews = () => {
    return (
        <section className='reviews-section section-padding'>
            <Container>
                <Row>
                    <Col md={12}>
                        <SectionHeading topTitle='মন্তব্য' desc='গৃহত্যাগী সম্পর্কে আপনাদের মন্তব্য' />
                    </Col>
                </Row>
                <Row className='align-center'>
                    <Col lg={5} className='text-center text-md-start'>
                        <img src={reviewImg} alt="Reviews | Grihotagi" className='mobWidth' />
                    </Col>
                    <Col lg={5} className='position-relative'>
                        <Carousel className='reviews-carousel text-center text-md-start' touch={true} indicators={false}>
                            <Carousel.Item className='single-review'>
                                <p>ভ্রমণ জগতে প্রগাঢ় ভালোবাসার অক্ষরে লেখা থাকুক গৃহত্যাগীর নাম। সর্বোচ্চ নিরাপত্তা, গাইডলাইন নিশ্চিত করা হয়। গৃহত্যাগী তার গৃহ ছেড়ে ভ্রমণ জগতের এক সর্বোচ্চ চূড়ায় পৌছাক</p>
                                <h6>Fazle Rabbi</h6>
                            </Carousel.Item>
                            <Carousel.Item className='single-review'>
                                <p>গৃহত্যাগী নয় শুধু ভ্রমণ গ্রুপ, গৃহত্যাগী হলো একটা পরিবার। ঘর ছেড়ে যারা ঘুরতে বেরুই তাদের, আপনার, আমার সবার।</p>
                                <h6>R.I. Tuhin</h6>
                            </Carousel.Item>
                            <Carousel.Item className='single-review'>
                                <p>কম খরচে এমন দারুন ট্যুর হয় জানতাম না। গৃহত্যাগীর সাথে ঘুরে দারুণ ভালো লেগেছে। গৃহত্যাগীর হোস্টরা টাইমের গুরুত্ব ভালো দেয়। খাবার মান ভালো।</p>
                                <h6>Mim Jahan Shakhi</h6>
                            </Carousel.Item>
                            <Carousel.Item className='single-review'>
                                <p>গৃহত্যাগীর সাথে রাঙ্গামাটি ঘুরে এলাম। এত কম বাজেটে এমন ট্যুর কল্পনাই করিনি।</p>
                                <h6>সাব্বির রুমান শরীফ</h6>
                            </Carousel.Item>
                            <Carousel.Item className='single-review'>
                                <p>আপনাদের সাথে সাজেট ট্যুরটা সত্যি মনে রাখার মত। কেয়ারিং এর দিক দিয়ে আপনাদের হোস্টকে ১০০ নম্বর দিবো। তবে কাপল রুমে আমার স্ত্রী প্রাইভেট বেলকুনি না পাওয়ায় মন খারাপ করছিলো। কিন্তু রাতে বিশাল কমন বেলকুনিতে বসে তার মন ভালো হয়ে যায়।</p>
                                <h6>Isteak Parvez</h6>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Reviews;
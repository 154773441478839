import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useStore from "../../GlobalProvider/useStore";
import InnerBanner from "../../component/InnerBanner/InnerBanner";
import { Helmet } from "react-helmet-async";

const Hotels = () => {
  const { allHotels } = useStore();
  return (
    <div>
      <Helmet>
          <title>Hotels | Grihotagi</title>
      </Helmet>
      <InnerBanner
        title="Our Hotels"
        desc="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate velit fugit ratione voluptates, dolor, eius reprehenderit doloribus consectetur dolore, tempora corrupti impedit nesciunt laborum cum."
      />
      <section className="events-page-area section-padding">
        <Container>
          <Row className="justify-content-center">
            {
                allHotels?.map((hotel,i)=><Col key={i} md={4}>
              <div
                className="single-blog-post bg-white"
                style={{ boxShadow: "var(--shadow-small)" }}
              >
                <div
                  className="post-thumb"
                  style={{ height: "250px", overflow: "hidden" }}
                >
                  <img
                    src={`${process.env.REACT_APP_HOTEL_IMG}/${hotel?.img}`}
                    alt="Flying Bird"
                    width="300px"
                    height="250px"
                    style={{
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </div>
                <div className="post-body p-4">
                    <h2
                      className="text-start mb-5"
                      style={{ fontSize: "22px" }}
                    >
                      <Link to={`/hotels/${hotel?.hotel_id}`} style={{ color: "rgba(var(--text-mc))" }}>
                        {hotel?.hotel_name}
                      </Link>
                    </h2>
                    <Link
                       to={`/hotels/${hotel?.hotel_id}`}
                      style={{ color: "rgba(var(--mc))", fontWeight: "bold" }}
                    >
                      Read More &#10140;
                    </Link>
                  </div>
                </div>
            </Col>)
            }
            
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Hotels;
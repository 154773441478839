import React from "react";
import InnerBanner from "../../../component/InnerBanner/InnerBanner";
import About from "../../../component/About/About";
import Motivate from "../../../component/Motivet/Motivate";
import Reviews from "../../../component/Reviews/Reviews";
import { Helmet } from "react-helmet-async";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Grihotagi</title>
        <meta name="description" content="আপনার দেশকে জানা শুরু হোক ’গৃহত্যাগী’-র সাথে। দেশের প্রতিটি ইঞ্চি ঘুরে বেড়ানোর থেকেই আমাদের পথ চলার শুরু। ঘুরতে ভালোবাসা মানুষদের জন্যই আমাদের আয়োজন।" />
        <link rel="canonical" href="/about-us" />
        <meta property="og:title" content="About Us | Grihotagi" />
        <meta property="og:description" content="Changed" />
        <meta property="og:url" content="https://grihotagi.com/about-us" />
        <meta property="og:image" content="https://grihotagi.com/server/images/event/slider/mhkHFQTxOa3y9ekixKng.jpg" />
        <meta property="og:image:secure_url" content="https://grihotagi.com/server/images/event/slider/mhkHFQTxOa3y9ekixKng.jpg" />
      </Helmet>
      <InnerBanner
        title="গৃহত্যাগী সম্পর্কে"
        desc="দেশের প্রতিটি ইঞ্চি ঘুরে বেড়ানো থেকেই আমাদের পথচলার শুরুটা।  একই সাথে তুলে ধরবো লোক  সংস্কৃতি, ঐতিহ্য আর আমাদের সৌন্দর্য্য।"
      />
      <About />
      <Motivate />
      <Reviews />
    </>
  );
};

export default AboutPage;

import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Navigate, useLocation } from 'react-router-dom';
import useStore from '../GlobalProvider/useStore';

const AdminRoute = ({ children }) => {
    let location = useLocation();
    const { admin,adminLoading } = useStore();
    if (adminLoading) {
      return <div className='text-center p-5 m-5'><Spinner animation="border" size="sm" /></div>
  }
    if (admin?.admin === true) {
      return children;
    }
  
    return <Navigate to="/" state={{ from: location }} />;

    
  };
  
  export default AdminRoute;
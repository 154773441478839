import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Container, FloatingLabel, Row, Tab, Table, Tabs,Form } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router-dom';
import useStore from '../../GlobalProvider/useStore';
import jwt_decode from "jwt-decode";
import parse from 'html-react-parser';
import { Editor } from "@tinymce/tinymce-react";
import { CalendarEvent, Clock, EmojiWink, People, PersonX, Truck ,GeoAlt, Check, X, Trash3} from 'react-bootstrap-icons';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
const TourDetails = () => {
    const editorRef = useRef(null);
    const {id} = useParams()
    const { register, handleSubmit } = useForm();
    const { allBookings,setAllBookings,authUser,selectedBooking,allEvents,setSelectedBooking,eventCall,setEventCall,admin,callBookings, setCallBookings } = useStore();
    const selectedEvent = allEvents?.find(event => event?.id === +id);
    const [update,setUpdate] = useState(false)
    const allImages = selectedEvent?.images?.split('_');
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BASE_URL}/booking/packBooking/${id}`,{
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, // notice the Bearer before your token
            },
        }).then(res => res.json())
        .then(data =>{
            if (data.code === 200) {
                setSelectedBooking(data?.data)
            }
        })
    },[id,setSelectedBooking,callBookings])
      if (!selectedEvent) {
        return <p>Loading...</p>  
      }
    if(authUser === null){
        return <Navigate to="/login" replace={true} /> ;
    }
    const loginUser = jwt_decode(authUser);

    const accept = (id) =>{
        fetch(`${process.env.REACT_APP_BASE_URL}/booking/${id}?status=approve`,{
            method:'PATCH'
        })
        .then(res => res.json())
        .then(data => {
            if (data.code === 200) {
                toast.success('Booking Accepted!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                let finalBooking = [];
                for (const booking of allBookings) {
                    if (booking.booking_id === id) {
                        booking.status = 'approve'
                    }
                    finalBooking.push(booking)
                }
                setAllBookings(finalBooking)
                setCallBookings(!callBookings)
            }
        }).catch((error) => {
            const errorMessage = error.message;
            toast.error(errorMessage, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
        });
      }
      const deny = (id) =>{
        fetch(`${process.env.REACT_APP_BASE_URL}/booking/${id}?status=deny`,{
            method:'PATCH'
        })
        .then(res => res.json())
        .then(data => {
            if (data.code === 200) {
                toast.warn('Booking Denyed!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                let finalBooking = [];
                for (const booking of allBookings) {
                    if (booking.booking_id === id) {
                        booking.status = 'deny'
                    }
                    finalBooking.push(booking)
                }
                setAllBookings(finalBooking)
                setCallBookings(!callBookings)
            }
        }).catch((error) => {
            const errorMessage = error.message;
            toast.error(errorMessage, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
        });
      }
      const remove = (id) =>{
        if (window.confirm('Are you sure you want to remove this booking?')) {
            fetch(`${process.env.REACT_APP_BASE_URL}/booking/${id}`,{
                method:'DELETE'
            }).then(res => res.json()).then(data => {
                if (data.code === 200) {
                    toast.warn('Booking Deleted!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    let finalBooking = allBookings.filter(booking => booking.booking_id !== id);
                    setAllBookings(finalBooking)
                    setCallBookings(!callBookings)
                }
            }).catch((error) => {
                const errorMessage = error.message;
                toast.error(errorMessage, {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
            });
        }
      }
      const handleAddTour = (data) => {
        if (editorRef.current) {
          data.plan = `${editorRef.current.getContent()}`;
        }
      };
      const onSubmit = (data) => {
        const formData = new FormData();
        if (data?.images.length) {
            for (let i = 0; i < data?.images.length; i++) {
                formData.append(`img${i + 1}`, data?.images[i]);
              }
              data.oldImages = selectedEvent?.images
        }else{
            data.images = selectedEvent?.images
        }
        handleAddTour(data);
        formData.append("data", JSON.stringify(data));
        const updateTost = toast.loading('Please Wait...', {
            position: "bottom-right",
        });
        fetch(`${process.env.REACT_APP_BASE_URL}/events/updateEvent/${selectedEvent?.id}`, {
            method: "PATCH",
            body: formData,
        }).then((res) => res.json()).then((data) => {
            if (data.code === 200) {
                toast.update(updateTost, {
                    render: "Event Updated!", 
                    type: "success",
                    isLoading: false,
                    closeOnClick: true,
                    autoClose: 5000
                });
                setEventCall(!eventCall)
            }
        }).catch((error) => {
            const errorMessage = error.message;
            toast.update(updateTost, {
                render: errorMessage, 
                type: "error",
                isLoading: false,
                closeOnClick: true
            });
        });
      }

      
    return (
        <section className='section-padding'>
            <Container>
                <Row>
                    <Col md={12}>
                        <Card className="text-center text-md-start shadow-sm shadow mb-5 position-sticky" style={{top: '75px',}}>
                            <Card.Body className="p-4">
                                <Row>
                                    <Col md={2}>
                                        <img src={loginUser?.picture} className="rounded-circle avatar-lg img-thumbnail w-100" alt="" />
                                    </Col>
                                    <Col md={10} className='d-flex flex-column justify-content-center'>
                                        <h4 className="mb-0 mt-2">{loginUser?.name}</h4>
                                        <p>{loginUser?.email}</p>
                                        {admin?.admin && <p>Admin</p> }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={12}>
                    <Tabs defaultActiveKey="eventDetails" id="tour-details">
                        <Tab eventKey="eventDetails" title={update ? "Event Update" : "Event Details"}>
                            {
                                !update && <div>
                                <div className="inner-event-heading p-5">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h2>{selectedEvent?.event_name}</h2>
                                        <button onClick={()=>setUpdate(true)} className='border'>Update</button>
                                    </div>
                                    <p><GeoAlt size={18} />{selectedEvent?.location}</p>
                                    <p>{selectedEvent?.booked}/{selectedEvent?.seat} seat booked</p>
                                    <p>Available {parseInt(selectedEvent?.seat) - parseInt(selectedEvent?.booked)}</p>
                                </div>
                                <div className="content-area">
                                    <Row className="tour-info mt-4 mb-4">
                                        <Col md={4} xs={6} className='d-flex mb-3 align-center'>
                                            <div className="flex-shrink-0 light-icon">
                                                <Clock color='rgba(var(--mc))' size={20} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5>Duration</h5>
                                                <p>{selectedEvent?.duration}</p>
                                            </div>
                                        </Col>
                                        <Col md={4} xs={6} className='d-flex mb-3 align-center'>
                                            <div className="flex-shrink-0 light-icon">
                                                <People color='rgba(var(--mc))' size={20} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5>Group Size</h5>
                                                <p>{selectedEvent?.seat} Pepole</p>
                                            </div>
                                        </Col>
                                        <Col md={4} xs={6} className='d-flex mb-3 align-center'>
                                            <div className="flex-shrink-0 light-icon">
                                                <EmojiWink color='rgba(var(--mc))' size={20} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5>Tour Type</h5>
                                                <p>{selectedEvent?.type}</p>
                                            </div>
                                        </Col>
                                        <Col md={4} xs={6} className='d-flex mb-3 align-center mb-md-0'>
                                            <div className="flex-shrink-0 light-icon">
                                                <CalendarEvent color='rgba(var(--mc))' size={20} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5>Tour Date</h5>
                                                <p>{selectedEvent?.start_date}</p>
                                            </div>
                                        </Col>
                                        <Col md={4} xs={6} className='d-flex mb-3 align-center mb-md-0'>
                                            <div className="flex-shrink-0 light-icon">
                                                <PersonX color='rgba(var(--mc))' size={20} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5>Minimum Age</h5>
                                                <p>{selectedEvent?.min_age}</p>
                                            </div>
                                        </Col>
                                        <Col md={4} xs={6} className='d-flex mb-3 align-center mb-md-0'>
                                            <div className="flex-shrink-0 light-icon">
                                                <Truck color='rgba(var(--mc))' size={20} />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5>Pickup From</h5>
                                                <p>{selectedEvent?.pick_up}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='main-content-area'>
                                        {parse(selectedEvent?.plan)}
                                    </div>
                                </div>
                            </div>
                            }
                            
                            {
                                update && <div>
                                    <button onClick={()=>setUpdate(false)}>Back</button>
                                <form className='mt-3' onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col md={6}>
                                    <FloatingLabel
                                        controlId="floatingTitle"
                                        label="Title"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                        type="text"
                                        defaultValue={selectedEvent?.event_name}
                                        {...register("event_name")}
                                        placeholder="Title"
                                        />
                                    </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <div className="row">
                                        {
                                            allImages?.length > 0 && allImages?.slice(1,allImages?.length).map((img,i)=><div key={i} className='col-2'>
                                                <img src={`${process.env.REACT_APP_SLIDER_IMG}/${img}`} alt="" className="w-100" />
                                            </div>)
                                        }
                                        </div>
                                    <FloatingLabel
                                        controlId="floatingImages"
                                        label="Slider image"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                        type="file"
                                        multiple
                                        {...register("images")}
                                        placeholder="Slider images"
                                        />
                                    </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                    <FloatingLabel
                                        controlId="floatingLocation"
                                        label="Location"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                        type="text"
                                        defaultValue={selectedEvent?.location}
                                        {...register("location")}
                                        placeholder="Location"
                                        />
                                    </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                    <FloatingLabel
                                        controlId="floatingDate"
                                        label="Date"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                        type="date"
                                        {...register("start_date")}
                                        defaultValue={selectedEvent?.start_date}
                                        placeholder="Date"
                                        />
                                    </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                    <FloatingLabel
                                        controlId="floatingPrice"
                                        label="Price"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                        type="number"
                                        defaultValue={selectedEvent?.price}
                                        {...register("price")}
                                        placeholder="Price"
                                        />
                                    </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                    <FloatingLabel
                                        controlId="floatingBookPrice"
                                        label="Booking Money"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                        type="number"
                                        {...register("booking_money")}
                                        defaultValue={selectedEvent?.booking_money}
                                        placeholder="Booking Money"
                                        />
                                    </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                    <FloatingLabel
                                        controlId="floatingChildPrice"
                                        label="Child Price"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                        type="number"
                                        {...register("child_price")}
                                        defaultValue={selectedEvent?.child_price}
                                        placeholder="Child Price"
                                        />
                                    </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                    <FloatingLabel
                                        controlId="floatingCouplePrice"
                                        label="Couple Extra"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                        type="number"
                                        {...register("couple_extra")}
                                        defaultValue={selectedEvent?.couple_extra}
                                        placeholder="Couple Extra"
                                        />
                                    </FloatingLabel>
                                    </Col>
                                    <Col md={12} className="mb-3">
                                    <Editor
                                        apiKey={process.env.REACT_APP_EDITOR_KEY}
                                        onInit={(evt, editor) => (editorRef.current = editor)}
                                        {...register("plan")}
                                        initialValue={selectedEvent?.plan}
                                        init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            "advlist",
                                            "autolink",
                                            "lists",
                                            "link",
                                            "image",
                                            "charmap",
                                            "preview",
                                            "anchor",
                                            "searchreplace",
                                            "visualblocks",
                                            "code",
                                            "fullscreen",
                                            "insertdatetime",
                                            "media",
                                            "table",
                                            "code",
                                            "help",
                                            "wordcount",
                                        ],
                                        toolbar:
                                            "undo redo | blocks | " +
                                            "bold italic forecolor media | alignleft aligncenter " +
                                            "alignright bullist numlist outdent indent removeformat",
                                        content_style:
                                            "body { font-family: -apple-system, BlinkMacSystemFont, Spectral, Georgia, serif; font-size:16px }",
                                        }}
                                    />
                                    </Col>
                                    <Col md={4}>
                                    <FloatingLabel
                                        controlId="floatingDuration"
                                        label="Duration"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                        type="text"
                                        defaultValue={selectedEvent?.duration}
                                        {...register("duration")}
                                        placeholder="Duration"
                                        />
                                    </FloatingLabel>
                                    </Col>
                                    <Col md={4}>
                                    <FloatingLabel
                                        controlId="floatingGroupSize"
                                        label="Group Size"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                        type="number"
                                        defaultValue={selectedEvent?.seat}
                                        {...register("seat")}
                                        placeholder="Group Size"
                                        />
                                    </FloatingLabel>
                                    </Col>
                                    <Col md={4}>
                                    <FloatingLabel
                                        controlId="floatingTourType"
                                        label="Tour Type"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                        type="text"
                                        defaultValue={selectedEvent?.type}
                                        {...register("type")}
                                        placeholder="Tour Type"
                                        />
                                    </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                    <FloatingLabel
                                        controlId="floatingMinimumAge"
                                        label="Minimum Age"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                        type="number"
                                        defaultValue={selectedEvent?.min_age}
                                        placeholder="Minimum Age"
                                        {...register("min_age")}
                                        />
                                    </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                    <FloatingLabel
                                        controlId="floatingPickupFrom"
                                        label="Pickup From"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                        type="text"
                                        defaultValue={selectedEvent?.pick_up}
                                        {...register("pick_up")}
                                        placeholder="Pickup From"
                                        />
                                    </FloatingLabel>
                                    </Col>

                                    <Col md={12}>
                                    <button className="button button-main mt-4">Update Event</button>
                                    </Col>
                                </Row>
                            </form>
                            </div>
                            }
                            
                        </Tab>
                        <Tab eventKey="eventBooking" title={`Booked Event (${selectedBooking?.length})`}>
                            <div className='scrollX'>
                            <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    {/* <th>Package Name</th> */}
                                    <th>Seat</th>
                                    <th>Couple</th>
                                    <th>Children</th>
                                    <th>Date</th>
                                    <th>Method</th>
                                    <th>Transaction ID</th>
                                    <th>Amount</th>
                                    <th>Other Persons</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selectedBooking?.map((booking,i)=> <tr key={i}>
                                    <td>{i+1}</td>
                                    <td>{booking?.user_name}</td>
                                    <td>{booking?.phone}</td>
                                    <td>{booking?.email}</td>
                                    {/* <td>{booking?.pack_name}</td> */}
                                    <td>{booking?.booking_seat}</td>
                                    <td>{booking?.couple === '1' ? 'Yes' : 'No'}</td>
                                    <td>{booking?.children}</td>
                                    <td>{booking?.booking_date}</td>
                                    <td>{booking?.pay_method}</td>
                                    <td>{booking?.transaction_id}</td>
                                    <td>{booking?.price}</td>
                                    <td>{booking?.persons}</td>
                                    <td className='text-capitalize'>{booking?.status}</td>
                                    <td>
                                        {booking?.status === 'pending' ? (
                                            <>
                                                <button className="btn btn-outline-success btn-sm me-1 rounded-circle" title='Accept' onClick={()=>accept(booking?.booking_id)}><Check /></button>
                                                <button className="btn btn-outline-warning btn-sm me-1 rounded-circle" title='Deny' onClick={()=>deny(booking?.booking_id)}><X /></button>
                                            </>
                                        ) : booking?.status === 'deny' ? (
                                            <button className="btn btn-outline-success btn-sm me-1 rounded-circle" title='Accept' onClick={()=>accept(booking?.booking_id)}><Check /></button>
                                        ) : (
                                            <button className="btn btn-outline-warning btn-sm me-1 rounded-circle" title='Deny' onClick={()=>deny(booking?.booking_id)}><X /></button>
                                        )}

                                        <button className="btn btn-outline-danger btn-sm me-1 rounded-circle" title='Delete' onClick={()=>remove(booking?.booking_id)}><Trash3 /></button>
                                    </td>
                                </tr>)
                                }
                            </tbody>
                            </Table>
                            </div>
                        </Tab>
                    </Tabs>
                    
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default TourDetails;
import React, { useRef } from "react";
import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { useForm } from "react-hook-form";
import useStore from "../../GlobalProvider/useStore";
import { toast } from "react-toastify";

const AddNewTours = () => {
  const {eventCall,setEventCall} = useStore();
  const { register, handleSubmit } = useForm();
  const editorRef = useRef(null);
  const handleAddTour = (data) => {
    if (editorRef.current) {
      data.plan = `${editorRef.current.getContent()}`;
    }
  };
  const formRef = useRef(null);

  const onSubmit = (data) => {
    const formData = new FormData();
    for (let i = 0; i < data.images.length; i++) {
        formData.append(`img${i + 1}`, data.images[i]);
    }
    delete data.images;
    handleAddTour(data);
    formData.append("data", JSON.stringify(data));
    const createTost = toast.loading('Please Wait...', {
      position: "bottom-right",
    });
    fetch(`${process.env.REACT_APP_BASE_URL}/events`, {
        method: "POST",
        body: formData,
    }).then((res) => res.json()).then((data) => {
      if (data.code === 200) {
        toast.update(createTost, {
          render: 'Event Added Successfully!', 
          type: "success",
          isLoading: false,
          closeOnClick: true,
          autoClose: 5000
        });
        setEventCall(!eventCall)
        formRef.current.reset();
      }
    }).catch((error) => {
      const errorMessage = error.message;
      toast.update(createTost, {
        render: errorMessage, 
        type: "error",
        isLoading: false,
        closeOnClick: true,
        autoClose: 5000
      });
    });
  };

  return (
    <Card className="shadow border-top-0 rounded-0 shadow-sm">
      <Card.Body className="p-4">
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingTitle"
                label="Title"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  {...register("event_name")}
                  placeholder="Title"
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingImages"
                label="Slider image"
                className="mb-3"
              >
                <Form.Control
                  type="file"
                  // onChange={imageChange}
                  multiple
                  {...register("images")}
                  placeholder="Slider images"
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingLocation"
                label="Location"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  {...register("location")}
                  placeholder="Location"
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingDate"
                label="Date"
                className="mb-3"
              >
                <Form.Control
                  type="date"
                  {...register("start_date")}
                  placeholder="Date"
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingPrice"
                label="Price"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  {...register("price")}
                  placeholder="Price"
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingBookPrice"
                label="Booking Money"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  {...register("booking_money")}
                  placeholder="Booking Money"
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingChildPrice"
                label="Child Price"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  {...register("child_price")}
                  placeholder="Child Price"
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingCouplePrice"
                label="Couple Extra"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  {...register("couple_extra")}
                  placeholder="Couple Extra"
                />
              </FloatingLabel>
            </Col>
            <Col md={12} className="mb-3">
              <Editor
                apiKey={process.env.REACT_APP_EDITOR_KEY}
                onInit={(evt, editor) => (editorRef.current = editor)}
                {...register("plan")}
                initialValue=""
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor media | alignleft aligncenter " +
                    "alignright bullist numlist outdent indent removeformat",
                  content_style:
                    "body { font-family: -apple-system, BlinkMacSystemFont, Spectral, Georgia, serif; font-size:16px }",
                }}
              />
            </Col>
            <Col md={4}>
              <FloatingLabel
                controlId="floatingDuration"
                label="Duration"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  defaultValue="1 Days, 1 Night"
                  {...register("duration")}
                  placeholder="Duration"
                />
              </FloatingLabel>
            </Col>
            <Col md={4}>
              <FloatingLabel
                controlId="floatingGroupSize"
                label="Group Size"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  defaultValue="0 People"
                  {...register("seat")}
                  placeholder="Group Size"
                />
              </FloatingLabel>
            </Col>
            <Col md={4}>
              <FloatingLabel
                controlId="floatingTourType"
                label="Tour Type"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  defaultValue="Relax"
                  {...register("type")}
                  placeholder="Tour Type"
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingMinimumAge"
                label="Minimum Age"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  defaultValue="10"
                  placeholder="Minimum Age"
                  {...register("min_age")}
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingPickupFrom"
                label="Pickup From"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  defaultValue="Dhaka"
                  {...register("pick_up")}
                  placeholder="Pickup From"
                />
              </FloatingLabel>
            </Col>

            <Col md={12}>
              <button className="button button-main mt-4">Add New Tour</button>
            </Col>
          </Row>
        </form>
      </Card.Body>
    </Card>
  );
};

export default AddNewTours;
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ParallaxBanner, ParallaxBannerLayer, ParallaxProvider } from 'react-scroll-parallax';
import bgImage from '../../assets/sub-page.webp';
import './innerBanner.css';

const InnerBanner = ({title, desc}) => {
    const peralaxStyle = {
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '2'
    }
    return (
        <ParallaxProvider>
            <ParallaxBanner className={`inner-banner-Peralax section-padding${desc ? '' : ' no-desc'}`}>
                <ParallaxBannerLayer image={bgImage} speed={20} />
                <ParallaxBannerLayer style={peralaxStyle} speed={1}>
                    <Container>
                        <Row>
                            <Col xl={{span: 8, offset: 2}} className='inner-banner text-center'>
                                {title && <h1 style={{color: 'rgba(var(--mct))'}}>{title}</h1>}
                                {desc && <p className='fw-normal' style={{color: 'rgba(var(--mct))'}}>{desc}</p>}
                            </Col>
                        </Row>
                    </Container>
                </ParallaxBannerLayer>
            </ParallaxBanner>
        </ParallaxProvider>
    );
};

export default InnerBanner;
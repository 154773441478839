import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionHeading from "../Global/SectionHeading";
import useStore from "../../GlobalProvider/useStore";
import BlogPost from "../BlogPost/BlogPost";
import { Link } from "react-router-dom";

const HomeBlogs = () => {
  const { allBlogs } = useStore();
  return (
    <section
      className="blog-posts-section section-padding"
      style={{ backgroundColor: "rgba(var(--tc))" }}
    >
      <Container>
        <Row>
          <Col md={12}>
            <SectionHeading topTitle="ব্লগ পোস্ট" desc="ভ্রমণ গল্প ও আলোচনা"/>
          </Col>
        </Row>
        <Row>
          {allBlogs?.filter(blog => blog.status === "approve").slice(0, 3).map((blog, i) => (
            <BlogPost key={i} blog={blog} />
          ))}
        </Row>
        <Row className="mt-4">
          <Col className="text-center">
            <Link to="/blog" className="button">সবগুলো পোস্ট দেখুন</Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeBlogs;

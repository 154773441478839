import React from 'react';
import { useParams } from 'react-router-dom';
import useStore from '../../GlobalProvider/useStore';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet-async';
import InnerBanner from '../../component/InnerBanner/InnerBanner';
import { Card, Col, Row } from 'react-bootstrap';
    
const UserBlogDetails = () => {
    const {allBlogs} = useStore();
    const {id} = useParams();
    const selectedBlog = allBlogs?.find(blog => blog?.blog_id === +id);
    if (!selectedBlog) {
        return <p>Loading...</p>  
      }
    return (
        <>
            <Helmet>
                <title>{selectedBlog?.title} | Grihotagi</title>
            </Helmet>
            <InnerBanner title={selectedBlog?.title} />
            <section className='single-blog-post section-padding'>
                <div className='container'>
                    <Row>
                        <Col md={8}>
                            <div className='single-blog-post-content main-content-area'>
                                <img src={`${process.env.REACT_APP_Blog_IMG}/${selectedBlog?.blog_img.includes('_') ? selectedBlog?.blog_img.split('_')[1] : selectedBlog?.blog_img}`} alt={selectedBlog?.title} width='300px' height='250px' style={{height: 'auto', width: '100%'}} />
                                {parse(selectedBlog?.texts)}
                            </div>
                        </Col>
                        <Col md={4}>
                            <Card className="text-center shadow-sm shadow mb-5 mb-md-0 position-sticky" style={{top: '75px',}}>
                                <Card.Body className="p-4">
                                    <h2 className='p-2 mb-3 fs-4' style={{backgroundColor: 'rgb(var(--mc))', color: '#FFF'}}>Author</h2>
                                    <img src={selectedBlog?.author_img} className="rounded-circle avatar-lg img-thumbnail w-50" alt="" />
                                    <h4 className="mb-0 mt-2">{selectedBlog?.author}</h4>
                                    <p className='text-capitalize'>{selectedBlog?.role}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    );
};

export default UserBlogDetails;
import React from 'react';
import { Container, Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import jwt_decode from "jwt-decode";
import { Navigate } from 'react-router-dom';
import Tours from '../../component/Tours/Tours';
import CreateNewPost from '../../component/CreateNewPost/CreateNewPost';
import useStore from '../../GlobalProvider/useStore';
import MyBlog from '../MyBlog/MyBlog';
import { Helmet } from 'react-helmet-async';

const Dashboard = () => {
    const {authUser} = useStore();
    if(authUser === null){
        return <Navigate to="/login" replace={true} /> ;
    }
    const loginUser = jwt_decode(authUser);
    return (
        <>
            <Helmet>
                <title>Profile | Grihotagi</title>
            </Helmet>
            <section className='section-padding'>
                <Container>
                    <Row>
                        <Col lg={4} className='mb-5 mb-lg-0'>
                            <Card className="text-center shadow-sm shadow mb-5 mb-md-0 position-sticky" style={{top: '75px',}}>
                                <Card.Body className="p-4">
                                    <img src={loginUser.picture} className="rounded-circle avatar-lg img-thumbnail w-50" alt="" />
                                    <h4 className="mb-0 mt-2">{loginUser.name}</h4>
                                    <p>{loginUser.email}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={8}>
                            <Tabs defaultActiveKey="tours" id="profile-tabs">
                                <Tab eventKey="tours" title="Tours">
                                    <Tours />
                                </Tab>
                                <Tab eventKey="myProfilePost" title="Create New Post">
                                    <CreateNewPost />
                                </Tab>
                                <Tab eventKey="myBlog" title="My Posts">
                                    <MyBlog />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Dashboard;
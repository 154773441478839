import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import './BlogPost.css'
const BlogPost = (props) => {
    const {blog_id,author,date,blog_img,title,author_img,role} = props?.blog || {};
    // console.log(blog)
    return (
        <Col lg={4} md={6}>
            <LazyLoad height={500}  offset={100} threshold={0.95}>
                <div className="single-blog-post bg-white" style={{boxShadow: 'var(--shadow-small)'}}>
                    <div className="post-thumb" style={{height: '250px', overflow: 'hidden'}}>
                        <img  src={`${process.env.REACT_APP_Blog_IMG}/${blog_img.includes('_') ? blog_img.split('_')[1] : blog_img}`} alt={title} width='300px' height='250px' style={{height: '100%', objectFit: 'cover', objectPosition: 'center'}} />
                    </div>
                    <div className="post-body p-4">
                        <div className="post-body-user-info-wraper d-flex align-center justify-content-between mb-4">
                            <div className="post-body-user-info d-flex align-center">
                                <div className="flex-shrink-0">
                                    <img style={{width: '40px', height: '40px', objectFit: 'cover', borderRadius: '100%'}} src={author_img} alt={author} width='40px' height='40px' />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <span className='fs-teen' style={{fontWeight: '700'}}>{author}</span>
                                    <span className='d-block fs-teen text-capitalize'>{role}</span>
                                </div>
                            </div>
                            <div className="post-date">
                                <span className='fs-teen' style={{fontWeight: '700'}}>{date}</span>
                            </div>
                        </div>
                        <div className="post-body-title-wraper text-end">
                            <h2 className='text-start mb-5' style={{fontSize: '22px'}}><Link to={`/blog/${blog_id}`} style={{color: 'rgba(var(--text-mc))'}}>{title}</Link></h2>
                            <Link to={`/blog/${blog_id}`} style={{color: 'rgba(var(--mc))', fontWeight: 'bold'}}>সম্পূর্ণটি পড়ুন &#10140;</Link>
                        </div>
                    </div>
                </div>
            </LazyLoad>
        </Col>
    );
};

export default BlogPost;
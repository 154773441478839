import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useStore from '../GlobalProvider/useStore';
import jwt_decode from "jwt-decode";

const PrivateRoute = ({ children }) => {
    let location = useLocation();
    const { authUser,userToken } = useStore();
    if (userToken === null) {
        return <Navigate to="/login" state={{ from: location }} />
    }else{
        const loginUser = jwt_decode(authUser);
        if (!loginUser?.email) {
          return <p>loading</p>;
        }
        if (loginUser?.email || loginUser?.phoneNumber) {
          return children;
        }
    }
    
  };
  
  export default PrivateRoute;
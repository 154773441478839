import React from "react";
import { getAuth, signOut } from "firebase/auth";
// import { UserContext } from '../../Context';
import { Dropdown, NavItem } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import useStore from "../../GlobalProvider/useStore";

const LogOut = () => {
  // const [authUser, setAuthUser] = useContext(UserContext);
  const { authUser, setAuthUser,admin } = useStore();
  const handleLogout = () => {
    setAuthUser(null);
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        sessionStorage.removeItem("userToken");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const { name, email, picture } = jwt_decode(authUser);
  return (
    <Dropdown align="end" as={NavItem} id="nav-dropdown">
      <Dropdown.Toggle
        as={NavItem}
        className="sa-dropdown-toogle position-relative"
      >
        <img
          className="avatar-img rounded-circle"
          src={picture}
          alt=""
          style={{ maxWidth: "40px" }}
          referrerPolicy="no-referrer"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-end p-0 overflow-hidden">
        <Dropdown.Item as={NavItem} eventKey="4.1">
          <div className="d-flex align-items-center p-2">
            <div className="avatar me-3">
              <img className="avatar-img rounded-circle shadow" src={picture} alt="avatar" style={{ maxWidth: "30px" }} referrerPolicy="no-referrer" />
            </div>
            <div>
              <p> <strong>{name}</strong> </p>
              <p className="small text-dark fw-semibold">{email}</p>
            </div>
          </div>
        </Dropdown.Item>
        <hr className="m-0" />
        {admin?.admin && <><Dropdown.Item as={NavItem} eventKey="4.2" className="p-0"> <Link to="admin" className="w-100 p-2 px-3 bg-transparent text-start d-block fw-semibold text-dark">Admin</Link></Dropdown.Item><hr className="m-0" /></>}
        <Dropdown.Item as={NavItem} eventKey="4.3" className="p-0">
          <Link to="dashboard" className="w-100 p-2 px-3 bg-transparent text-start d-block fw-semibold text-dark">Dashboard</Link>
        </Dropdown.Item>
        <hr className="m-0" />
        <Dropdown.Item as={NavItem} eventKey="4.4" className="p-0">
          <button onClick={handleLogout} className="w-100 p-2 px-3 bg-transparent text-start d-block fw-semibold text-dark">Logout</button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LogOut;

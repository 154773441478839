import { getAuth, onAuthStateChanged } from "firebase/auth";
// import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
const useProvider = () => {
  const auth = getAuth();
  const userToken = sessionStorage.getItem("userToken");
  const [authUser, setAuthUser] = useState(userToken);
  const [updateBlog,setUpdateBlog] = useState(false)
  const [user, setUser] = useState({});
  const [bookingData, setBookingData] = useState({});
  const [allEvents, setAllEvents] = useState([]);
  const [allBookings, setAllBookings] = useState([]);
  const [callBookings, setCallBookings] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);
  const [callBlogs, setCallllBlogs] = useState([]);
  const [allHotels, setAllHotels] = useState([]);
  const [callHotels,setCallHotels] = useState(false)
  const [admin,setAdmin] = useState({})
  const [allUsers,setAllUsers] = useState([])
  const [callUsers,setCallUsers] = useState([])
  const [adminLoading, setAdminLoading] = useState(true)
  const [selectedBooking,setSelectedBooking] = useState([])
  const [eventCall,setEventCall] = useState(false)

  useEffect(() => {

    fetch(`${process.env.REACT_APP_BASE_URL}/booking`,{
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, // notice the Bearer before your token
    },
    })
      .then((res) => res.json())
      .then((data) => setAllBookings(data?.data));
  }, [callBookings, eventCall]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/blog`,{
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, // notice the Bearer before your token
    },
    })
      .then((res) => res.json())
      .then((data) => setAllBlogs(data?.data));
  }, [callBlogs]);


  useEffect(() => {

    fetch(`${process.env.REACT_APP_BASE_URL}/hotel`,{
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, // notice the Bearer before your token
    },
    })
      .then((res) => res.json())
      .then((data) => setAllHotels(data?.data));
  }, [callHotels]);


  useEffect(() => {

    fetch(`${process.env.REACT_APP_BASE_URL}/users`,{
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, // notice the Bearer before your token
    },
    })
      .then((res) => res.json())
      .then((data) => setAllUsers(data?.data));
  }, [callUsers]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/events`,{
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, // notice the Bearer before your token
    },
    })
      .then((res) => res.json())
      .then((data) => setAllEvents(data?.data));
  }, [eventCall, callBookings]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setAdminLoading(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/users/${user.email}`,{
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, // notice the Bearer before your token
        },
        })
          .then((res) => res.json())
          .then((data) => setAdmin(data))
          .finally(()=> setAdminLoading(false))
      } else {
        setUser({});
      }
      return () => unsubscribe;
    });
  }, [auth]);
  return {
    authUser,
    setAuthUser,
    adminLoading,
    allEvents,
    bookingData,
    setBookingData,
    allBookings,
    allUsers,
    setAllBookings,
    allBlogs,
    setAllBlogs,
    userToken,
    allHotels,
    setAllHotels,
    user,
    setUser,
    admin,
    selectedBooking,setSelectedBooking,updateBlog,setUpdateBlog,eventCall,setEventCall, callHotels,setCallHotels, callBlogs, setCallllBlogs, callUsers,setCallUsers, callBookings, setCallBookings
  };
};
export default useProvider;
import React from 'react';
// import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { BoxArrowInRight } from 'react-bootstrap-icons';
import { Link, NavLink } from 'react-router-dom';
// import { UserContext } from '../../Context';
import useStore from '../../GlobalProvider/useStore';
import LogOut from '../../Pages/SignIn/LogOut';
import './Header.css';
import logo from '../../GT-Logo.png';

const Header = () => {
    const {authUser} = useStore();
    const openMenu = () => {
        const winWidth = window.outerWidth;
        if(winWidth < 992){
            const navbarMenu = document.getElementById("menu");
            const bgOverlay = document.querySelector(".overlay");
            navbarMenu.classList.add("is-active");
            bgOverlay.classList.toggle("is-active");
        }
    }
    const closeMenu = () => {
        const winWidth = window.outerWidth;
        if(winWidth < 992){
            const navbarMenu = document.getElementById("menu");
            const bgOverlay = document.querySelector(".overlay");
            navbarMenu.classList.remove("is-active");
            bgOverlay.classList.toggle("is-active");
        }
    }
    return (
        <header className="header" id="header">
            <Container>
                <nav className="navbar">
                    <Link to="/" className="brand"><img className='logoImg' src={logo} alt="Grihotagi Logo" /></Link>
                    <div className="burger" id="burger" onClick={openMenu}>
                        <span className="burger-line"></span>
                        <span className="burger-line"></span>
                        <span className="burger-line"></span>
                    </div>
                    <span className="overlay" onClick={closeMenu}></span>
                    <div className="menu" id="menu">
                        <ul className="menu-inner">
                            <li className="menu-item">
                                <NavLink to="/" className={({ isActive }) => isActive ? "menu-link active" : "menu-link"} onClick={closeMenu} end>Home</NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink to="events" className={({ isActive }) => isActive ? "menu-link active" : "menu-link"} onClick={closeMenu}>Events</NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink to="about-us" className={({ isActive }) => isActive ? "menu-link active" : "menu-link"} onClick={closeMenu}>About Us</NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink to="hotels" className={({ isActive }) => isActive ? "menu-link active" : "menu-link"} onClick={closeMenu}>Our Hotels</NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink to="blog" className={({ isActive }) => isActive ? "menu-link active" : "menu-link"} onClick={closeMenu}>Blog</NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink to="contact-us" className={({ isActive }) => isActive ? "menu-link active" : "menu-link"} onClick={closeMenu}>Contact</NavLink>
                            </li>
                            <li className="menu-item sa-new-login">
                                {authUser === null ? <Link to='login' className='menu-link'  onClick={closeMenu}>Login</Link> : <LogOut />}
                            </li>
                        </ul>
                    </div>
                    <span className="profile-login-bar">
                        {authUser === null ? <Link to='login' className='button button-main button-sm'><BoxArrowInRight size='18' className='me-1' /> Login</Link> : <LogOut />}
                    </span>
                </nav>
            </Container>
        </header>
    );
};

export default Header;
import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Trash3 } from "react-bootstrap-icons";
import LazyLoad from "react-lazy-load";
import { toast } from "react-toastify";
import useStore from "../../GlobalProvider/useStore";

const AllUsers = () => {
  const [searchText,setSearchText] = useState('')
  const {allUsers,callUsers,setCallUsers} = useStore()
  const [searchedUser,setSearchedUser] = useState([])
  useEffect(()=>{
    const allSearchedUser = allUsers.filter(user => user.user_name.toLowerCase().includes(searchText.toLocaleLowerCase()))
    setSearchedUser(allSearchedUser)
  },[searchText,allUsers])
  
  const removeuser = (id) =>{
    if (window.confirm('Are you sure you want to remove this user?')) {
      fetch(`${process.env.REACT_APP_BASE_URL}/users/${id}`,{
        method:'DELETE'
      }).then(res => res.json())
      .then(data => {
        if (data.code === 200) {
          toast.warn('User Deleted!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setCallUsers(!callUsers)
        }
      }).catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
  }
  const makeadmin = (id) => {
    if (window.confirm('Are you sure you want to this user as admin?')) {
      fetch(`${process.env.REACT_APP_BASE_URL}/users/${id}?role=admin`,{
        method:'PATCH'
      }).then(res => res.json())
      .then(data => {
        if (data?.code === 200) {
          toast.success('Set To Admin!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setCallUsers(!callUsers)
        }
      }).catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
  }
  const removeadmin = (id)=>{
    if (window.confirm('Are you sure?')) {
      fetch(`${process.env.REACT_APP_BASE_URL}/users/${id}?role=user`,{
        method:'PATCH'
      }).then(res => res.json())
      .then(data => {
        if (data.code === 200) {
          toast.warn('Removed From Admin!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setCallUsers(!callUsers)
        }
      }).catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
  }
  return (
    <Card className='shadow border-top-0 rounded-0 shadow-sm'>
      <Card.Body>
        <div className="col-12">
          <input type="text" name="" onChange={(e)=>setSearchText(e.target.value)} placeholder='Search by name' className="form-control" id="" />
        </div>
        <LazyLoad offset={100} threshold={0.95}>
        <Table>
          <thead>
            <tr>
              <th>*</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Role</th>
              <th>Joined</th>
              <th className="text-end">Action</th>
            </tr>
          </thead>
          <tbody>
          
            {searchedUser?.map((user, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td><img src={user?.img} alt="" className="avatar-img rounded-circle" style={{width: '30px'}} /> {user?.user_name}</td>
                <td>{user?.email}</td>
                <td>{user?.phone_number}</td>
                <td className="text-capitalize">{user?.role}</td>
                <td>{user?.total_bookings}</td>
                <td className="text-end">
                  {user?.email !== "bappy.llcg@gmail.com" && (
                    <>
                      {user?.role !== 'admin' ? (
                        <button className="me-1 btn btn-sm btn-outline-info" onClick={() => makeadmin(user?.user_id)}>Make Admin</button>
                      ) : (
                          <button className="me-1 btn btn-sm btn-outline-warning" onClick={() => removeadmin(user?.user_id)}>Remove Admin</button>
                      )}
                      <button className="btn btn-sm btn-outline-danger" onClick={() => removeuser(user?.user_id)}><Trash3 /></button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </LazyLoad>
      </Card.Body>
    </Card>
  );
};

export default AllUsers;

import React from 'react';
import {  Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import useStore from '../../GlobalProvider/useStore';
import jwt_decode from "jwt-decode";
import parse from 'html-react-parser';
const AdminBlogDetails = () => {
    const {allBlogs,authUser} = useStore();
    const loginUser = jwt_decode(authUser);
    const {id} = useParams();
    const selectedBlog = allBlogs.find(blog => blog?.blog_id === +id);
    if (!selectedBlog) {
        return <p>Loading...</p>  
      }
    return (
        <div>
            <div className="container">
                <Row>
                    <Col md={3}>
                        <Card className="text-center shadow-sm shadow mb-5 mb-md-0 position-sticky" style={{top: '75px',}}>
                            <Card.Body className="p-4">
                                <img src={loginUser?.picture} className="rounded-circle avatar-lg img-thumbnail w-50" alt="" />
                                <h4 className="mb-0 mt-2">{loginUser?.name}</h4>
                                <p>{loginUser?.email}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={9}>
                        <div className="pt-3 single-blog-post bg-white" style={{boxShadow: 'var(--shadow-small)'}}>
                            <div className="post-thumb" style={{height: '250px', overflow: 'hidden'}}>
                                <img src={`${process.env.REACT_APP_Blog_IMG}/${selectedBlog.blog_img.includes("_") ? selectedBlog.blog_img.split('_')[1] : selectedBlog.blog_img}`} alt="Flying Bird" width='300px' height='250px' style={{height: '100%', objectFit: 'cover', objectPosition: 'center'}} />
                            </div>
                            <div className="post-body p-4">
                                <div className="post-body-user-info-wraper d-flex align-center justify-content-between mb-4">
                                    <div className="post-body-user-info d-flex align-center">
                                        <div className="flex-shrink-0">
                                            <img style={{width: '40px', height: '40px', objectFit: 'cover', borderRadius: '100%'}} src={selectedBlog.author_img} alt="Flying Bird" width='40px' height='40px' />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <a href="#!" className='fs-teen' style={{fontWeight: '700'}}>{selectedBlog.author}</a>
                                            <span className='d-block fs-teen'>{selectedBlog.role}</span>
                                        </div>
                                    </div>
                                    <div className="post-date">
                                        <span className='fs-teen' style={{fontWeight: '700'}}>{selectedBlog.date}</span>
                                    </div>
                                </div>
                                <div className="post-body-title-wraper text-end">
                                    <h2 className='text-start mb-5' style={{fontSize: '22px'}}><a href="#!" style={{color: 'rgba(var(--text-mc))'}}>{selectedBlog.title}</a></h2>
                                </div>
                            </div>
                            <div>
                                {parse(selectedBlog?.texts)}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AdminBlogDetails;
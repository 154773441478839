import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import InnerBanner from '../../component/InnerBanner/InnerBanner';
import useStore from '../../GlobalProvider/useStore';
import Event from '../../component/Event/Event';
import { Helmet } from 'react-helmet-async';

const EventsPage = () => {
    const {allEvents} = useStore();
    return (
        <>
            <Helmet>
                <title>Events | Grihotagi</title>
                <meta name="description" content="আপনার দেশকে জানা শুরু হোক ’গৃহত্যাগী’-র সাথে। দেশের প্রতিটি ইঞ্চি ঘুরে বেড়ানোর থেকেই আমাদের পথ চলার শুরু। ঘুরতে ভালোবাসা মানুষদের জন্যই আমাদের আয়োজন।" />
                <link rel="canonical" href="/events" />
                <meta property="og:title" content="Events | Grihotagi" />
                <meta property="og:description" content="Changed" />
                <meta property="og:url" content="https://grihotagi.com/events" />
            </Helmet>
            <InnerBanner title='আমাদের ইভেন্ট' desc='সবসময় সকল ইভেন্টে আমরা যথাযথ মান বজায় রাখি। আমাদের ট্যুর সমুর্হে নিরাপত্তার দিকে আমরা সবচেয়ে বেশি মনযোগী। গৃহত্যাগীর সাথে আপনার পথচলা সুন্দর, মনোরম ও নিরাপদ করতে আমরা অঙ্গীকারবদ্ধ।' />
            <section className="events-page-area section-padding">
                <Container>
                    <Row className='justify-content-center'>
                       {
                        allEvents?.slice(0, allEvents?.length).map((event,i)=><Col key={i} xl={4} md={6}><Event data={event}/></Col>)
                       }
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default EventsPage;
import React from 'react';
import { Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useStore from '../../GlobalProvider/useStore';
import './setUserInfo.css'

const GetUserInfo = () => {
    const navigate = useNavigate()
    const {bookingData,setBookingData, callBookings, setCallBookings} = useStore()
    const { register, handleSubmit } = useForm();
    const onSubmit = data => {
        let allInfo = {...bookingData,...data}
        allInfo.persons = data.persons ? data.persons : 'empty';
        const bookingTost = toast.loading('Please Wait...', {
            position: "bottom-right",
        });
        fetch(`${process.env.REACT_APP_BASE_URL}/booking`,{
            method:'POST',
            headers: {'content-type':'application/json'},
            body:JSON.stringify(allInfo)
        }).then(res => res.json()).then(data => {
            if (data.code === 200) {
                toast.update(bookingTost, {
                    render: 'Event Booked!', 
                    type: "success",
                    isLoading: false,
                    closeOnClick: true,
                    autoClose: 5000
                });
                setBookingData({})
                allInfo = {}
                data = {}
                setCallBookings(!callBookings)
                navigate("/dashboard")
            }
        }).catch((error) => {
            const errorMessage = error.message;
            toast.update(bookingTost, {
                render: errorMessage, 
                type: "error",
                isLoading: false,
                closeOnClick: true,
                autoClose: 5000
            });
        });
    };
  const countPersionBooking = bookingData?.booking_seat + bookingData?.children;
    return (
        <div className='get-user-info event-book-area'>
            <Card>
                <Card.Header><span>Complite Your Booking</span></Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col md={6} className='mt-md-0 mt-0'>
                                <FloatingLabel controlId="floatingSelectName" label="Your Name."  >
                                    <Form.Control type="text" placeholder="Your Name" {...register("user_name")} className='border border-dark border-opacity-25 rounded-1' required />
                                </FloatingLabel>
                            </Col>
                            <Col md={6} className='mt-md-0 mt-3'>
                                <FloatingLabel controlId="floatingSelectEmail" label="Your Mobile."  >
                                    <Form.Control type="tel" placeholder="Your Mobile"  {...register("phone")} className='border border-dark border-opacity-25 rounded-1' required />
                                </FloatingLabel>
                            </Col>
                            <Col md={12} className='mt-3'>
                                <FloatingLabel controlId="floatingSelectAddress" label="Your Address."  >
                                    <Form.Control type="text" placeholder="Your Address"  {...register("address")} className='border border-dark border-opacity-25 rounded-1' required/>
                                </FloatingLabel>
                            </Col>
                            {
                                countPersionBooking > 1 && <Col md={12} className='mt-3'>
                                <FloatingLabel controlId="floatingTextarea2" label="Others Persons Name & Phone Number(Optional)">
                                    <Form.Control
                                    as="textarea"
                                    {...register("persons")}
                                    placeholder="Leave a comment here"
                                    style={{ height: '200px' }}
                                    />
                                </FloatingLabel>
                                </Col>
                            }
                            
                            <Col md={12} className='mt-3 text-center'>
                                <button type="submit" className='button button-main'>Confirm Booking</button>
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        </div>
    );
};

export default GetUserInfo;
import React from 'react';
import { Container, Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import jwt_decode from "jwt-decode";
import { Navigate } from 'react-router-dom';
import AddNewTours from './AddNewTours';
import AdminTours from './AdminTours';
import useStore from '../../GlobalProvider/useStore';
// import BookingTour from './BookingTour';
import Blogs from './Blogs';
import AddHotel from './AddHotel';
import AllHotel from './AllHotel';
import AllUsers from './AllUsers';
import { Helmet } from 'react-helmet-async';

const Admin = () => {
    const {authUser, admin} = useStore()
    if(authUser === null){
        return <Navigate to="/login" replace={true} /> ;
    }
    const loginUser = jwt_decode(authUser);
    return (
        <section className='section-padding'>
            <Helmet>
                <title>Admins | Grihotagi</title>
            </Helmet>
            <Container>
                <Row>
                    <Col md={3} className='mb-5'>
                        <Card className="text-center shadow-sm shadow mb-5 mb-md-0 position-sticky" style={{top: '75px',}}>
                            <Card.Body className="p-4">
                                <img src={loginUser?.picture} className="rounded-circle avatar-lg img-thumbnail w-50" alt="" />
                                <h4 className="mb-0 mt-2">{loginUser?.name}</h4>
                                <p>{loginUser?.email}</p>
                                {admin?.admin && <p>Admin</p> }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={9}>
                        <Tabs defaultActiveKey="tours" id="admin-profile-tabs">
                            <Tab eventKey="tours" title="Tours">
                                <AdminTours />
                            </Tab>
                            <Tab eventKey="addTours" title="Add New Tours">
                                <AddNewTours />
                            </Tab>
                            {/* <Tab eventKey="allBooking" title="All Booking">
                                <BookingTour />
                            </Tab> */}
                            <Tab eventKey="allBlogs" title="All Blogs">
                                <Blogs />
                            </Tab>
                            <Tab eventKey="addHotel" title="Add Hotels">
                                <AddHotel />
                            </Tab>
                            <Tab eventKey="allHotel" title="All Hotels">
                                <AllHotel />
                            </Tab>
                            <Tab eventKey="allUser" title="All Users">
                                <AllUsers/>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Admin;
import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { Eye, Trash } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useStore from '../../GlobalProvider/useStore';

const AdminTours = () => {
    const {allEvents,eventCall,setEventCall} = useStore()
    const navigate = useNavigate()
    const removeEvents = (id,images) => {
        if (window.confirm('Are you sure you want to remove this event?')) {
            fetch(`${process.env.REACT_APP_BASE_URL}/events/${id}`,{
                method:'DELETE',
                headers:{'content-type':'application/json'},
                body:JSON.stringify({images})
            }).then(res => res.json())
            .then(data =>{
                if (data.code === 200) {
                    toast.success('Event Removed Successfully!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setEventCall(!eventCall)
                }
            }).catch((error) => {
                const errorMessage = error.message;
                toast.error(errorMessage, {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
            });
        }
    }
    
    const goTourDetails = (id) =>{
        navigate(`/admin/tour-details/${id}`)
    }
    return (
        <div>
            <Card className='shadow border-top-0 rounded-0 shadow-sm'>
                <Card.Body className='p-4'>
                    <Table striped responsive='md'>
                        <thead>
                            <tr>
                                <th>*</th>
                                <th>Event Name</th>
                                <th>Date</th>
                                <th>Seat</th>
                                <th>Fee</th>
                                <th colSpan={2} className="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allEvents?.slice(0, allEvents?.length).map((event,i)=>

                                    <tr className={event?.unchecked_booking > 0 ? 'unchecked-booking' : 'checked-booking'} key={i}>
                                        <td>{i+1}</td>
                                        <td className='pointer' onClick={()=>goTourDetails(event?.id)}>{event?.event_name}</td>
                                        <td>{event.start_date}</td>
                                        <td>{event.booked || 0}/{event.seat}</td>
                                        <td>{event.price}</td>
                                        <td className='text-end'>
                                            <button className="btn btn-outline-danger btn-sm me-1 rounded-circle" onClick={()=>removeEvents(event?.id,event?.images)}><Trash /></button>
                                            <Link to={`/events/single-event/${event?.id}`} className="btn btn-outline-success btn-sm rounded-circle"><Eye /></Link>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    );
};

export default AdminTours;
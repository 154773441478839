import React from 'react';
import { useParams } from 'react-router-dom';

const HotelDetails = () => {
    const {id} = useParams();
    return (
        <section className='py-5 my-5'>
            <div className='py-5 my-5'>
                <div className="container">
                    hotel id {id}
                </div>
            </div>
        </section>
    );
};

export default HotelDetails;
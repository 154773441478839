import React from "react";
import { Card, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import useStore from "../../GlobalProvider/useStore";

const AllHotel = () => {
    const {allHotels, callHotels,setCallHotels} = useStore()
    const removeHotel = (id,img) =>{
      if(window.confirm('Are you sure you want to remove this hotel?')){
        fetch(`${process.env.REACT_APP_BASE_URL}/hotel/${id}`,{
          method:'DELETE',
          headers:{'content-type':'application/json'},
          body:JSON.stringify({img})
        }).then(res => res.json())
        .then(data => {
          if (data.code === 200) {
            toast.success('Hotel Removed!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setCallHotels(!callHotels);
          }
        }).catch((error) => {
          const errorMessage = error.message;
          toast.error(errorMessage, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
      }
    }
  return (
    <Card className='shadow border-top-0 rounded-0 shadow-sm'>
      <Card.Body>
        <Table>
          <thead>
            <tr>
              <th>Sl</th>
              <th>Hotel name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allHotels?.map((hotel, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{hotel?.hotel_name}</td>
                <td>
                  <button className="me-1" onClick={() => removeHotel(hotel?.hotel_id,hotel?.img)} >Remove</button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default AllHotel;

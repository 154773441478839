import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import heroImage from '../../assets/logo-banner.webp';
import bgCloud from '../../assets/bgCloud.webp';
import './Hero.css';


const Hero = () => {
    return (
        <section className='hero-section section-padding' style={{backgroundImage: `url(${bgCloud})`}}>
            <Container>
                <Row className='align-center'>
                    <Col md={{ order: 'last', span: 5 }} className="hero-right text-center">
                        <img src={heroImage} alt="Grihotagi" width='300' height='300' className='mb-5 mb-md-0' />
                    </Col>
                    <Col md={{ order: 'first', span: 7 }} className="hero-left text-center text-md-start">
                        <h1 className='mb-2'><span>গৃহত্যাগী </span>পথ চেনে পথের বাঁক...</h1>
                        <p className='mb-5'>আমাদের অভিজ্ঞতায় সমুদ্র, পাহাড় কিংবা ঝর্না বিচরণে গৃহত্যাগী হতে পারে আপনার বিশ্বস্ত ও প্রথম পছন্দ।</p>
                        <Link to={`/contact-us/`} className='button button-main'>যোগাযোগ করুন</Link>
                        <Link to={`/events/`} className='button ml-3'>ইভেন্টগুলি দেখুন</Link>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Hero;
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Alert, Card, FloatingLabel, Form, ListGroup } from "react-bootstrap";
import { Dash, Plus } from "react-bootstrap-icons";
import useStore from "../../GlobalProvider/useStore";
import GoogleSignIn from "../../Pages/SignIn/GoogleSignIn";
import "./EventBook.css";
import jwt_decode from "jwt-decode";
import { useParams } from "react-router-dom";
const EventBook = (props) => {
  // const { authUser,setBookingData } = useStore();
  const {id} = useParams()
  const { authUser,setBookingData, allEvents } = useStore();

  const [errBookForm, setErrBookForm] = useState(null);
  const {
    adult,
    selectedEvent,
    setAdult,
    child,
    setChild,
    couple,
    setCouple,
    pay,
    setPay,
    paymentMethod,
    setPaymentMethod,
    fee,
    setFee,
    totalPrice,
    setTotalPrice,
    price,
    setPrice,
    defaultPrice,
    bookingMoney,
    couplePrice,
    childPrice,
    tnxid,
    setTnxId,
    clickBookNow,
    setclickBookNow,
  } = props.data;
  const date = new Date();

let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
let currentDate = `${day}-${month}-${year}`;
  useEffect(() => {
    let nowPrice = defaultPrice;
    let nowPriceChild = childPrice;
    // Booking Money Price
    if (pay === "Full Payment" && couple) {
      nowPrice = nowPrice + couplePrice;
      nowPriceChild = childPrice;
    } else if (pay === "Booking Money" && couple) {
      const devideCouplePrice = couplePrice / 2;
      nowPrice = bookingMoney + devideCouplePrice;
      nowPriceChild = childPrice / 2;
    } else if (pay === "Full Payment") {
      // eslint-disable-next-line no-self-assign
      nowPrice = nowPrice;
      nowPriceChild = childPrice;
    } else if (pay === "Booking Money") {
      nowPrice = bookingMoney;
      nowPriceChild = childPrice / 2;
    }
    const adultNowPrice = nowPrice * adult;
    const childNowPrice = nowPriceChild * child;

    const finalPrice = adultNowPrice + childNowPrice; //Final Price
    // Fees
    let mfsFee = 0;
    if (paymentMethod === "Bkash") {
      mfsFee = 0.02;
    } else if (paymentMethod === "Rocket") {
      mfsFee = 0.02;
    } else if (paymentMethod === "Nagad") {
      mfsFee = 0.015;
    } else if (paymentMethod === "DBBL") {
      mfsFee = 0;
    } else if (paymentMethod === "Cash") {
      mfsFee = 0;
    }
    const feeAmount = finalPrice * mfsFee;
    const feeAmmountFinal = Math.round(feeAmount); //Fee

    setFee(feeAmmountFinal);
    setPrice(finalPrice);
    setTotalPrice(finalPrice + feeAmmountFinal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adult, pay, child, couple, paymentMethod]);
    let bookData = {}
    bookData.id=selectedEvent?.id;
    bookData.pack_name=selectedEvent?.event_name;
    bookData.pack_price=selectedEvent?.price;
    bookData.booking_date=currentDate;
    bookData.booking_seat=adult;
    bookData.children=child;
    bookData.pay_status=pay;
    bookData.pay_method=paymentMethod;
    bookData.price=price;
    bookData.email=authUser && jwt_decode(authUser).email;
    bookData.booked=selectedEvent?.booked;
    bookData.fee=fee;
    bookData.transaction_id=tnxid;
    bookData.isComplete= 'false';
    bookData.couple= couple;
    bookData.adult= adult;
    
  const handleBook = (event) => {
    event.preventDefault();
    setErrBookForm(null);
    if(paymentMethod === 'Cash'){
      setclickBookNow(true);
      setBookingData(bookData)
    }else if(tnxid.length === 0) {
      setErrBookForm("Please insert a TNX ID / Number Last 4 Digit..");
    }else {
      setclickBookNow(true);
      setBookingData(bookData)
    }
  };

  // Sit Availavle
  const thisEvent = allEvents.find(event => +event.id === +id);
  const {seat, booked, start_date} = thisEvent;
  const [availableSit, setAvailableSit] = useState(seat - booked);
  const [disableBooking, setdisableBooking] = useState(false);

  const [event_date, setEvent_date] = useState(start_date)
  useEffect(()=>{
    const eventDate = new Date(event_date);
    if (eventDate < new Date()) {
      setdisableBooking(true);
      setErrBookForm(`This tour ended at ${start_date}`);
      setEvent_date(eventDate);
    }

  },[event_date, start_date])

  useEffect(() => {
    const countAvailabelSit = seat - booked - adult;
    setAvailableSit(countAvailabelSit);
    if(availableSit < 0){
      setdisableBooking(true);
      setErrBookForm(`দুক্ষিত, শুধুমাত্র ${seat - booked} টি আসন খালি রয়েছে!`);
    }else {
      setdisableBooking(false);
      setErrBookForm(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[adult, child, availableSit])

  



  return (
    <div className="event-book-area">
      <form>
        <Card>
          <Card.Header className="d-flex justify-content-between align-center">
            <span>Book Now</span>
            <span>৳{defaultPrice}</span>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              <span>Journy Date:</span>
              <span className="float-end">{start_date}</span>
            </Card.Text>
          </Card.Body>
          <ListGroup
            className={`list-group-flush ${clickBookNow && "no-event"}`}
          >
            <ListGroup.Item className="d-flex justify-content-between align-center">
              <span>
                Adults <em>5+ Years</em>
              </span>
              <div className="counter d-flex align-center">
                <button
                  type="button"
                  onClick={() => setAdult(adult - 1)}
                  disabled={adult <= 1 ? true : false}
                >
                  <Dash />
                </button>
                <p>{adult}</p>
                <button type="button" onClick={() => setAdult(adult + 1)}>
                  <Plus />
                </button>
              </div>
            </ListGroup.Item>
            {adult > 1 && (
              <ListGroup.Item>
                <Form.Check
                  type="switch"
                  id="couple-switch"
                  label="Are You Couple?"
                  defaultChecked={couple}
                  onChange={(event) => setCouple(event.target.checked)}
                />
              </ListGroup.Item>
            )}
            <ListGroup.Item className="d-flex justify-content-between align-center">
              <span>
                Child <small>+{childPrice}</small> <em>1 - 5 Years</em>
              </span>
              <div className="counter d-flex align-center">
                <button
                  type="button"
                  onClick={() => setChild(child - 1)}
                  disabled={child <= 0 ? true : false}
                >
                  <Dash />
                </button>
                <p>{child}</p>
                <button type="button" onClick={() => setChild(child + 1)}>
                  <Plus />
                </button>
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex flex-wrap gap-3 list-group-item">
              <Form.Check className="fs-6" checked={pay === 'Full Payment' ? true : false} onChange={(event) => setPay(event.target.value)} label="Full Payment" value="Full Payment" name="group1" type={"radio"} id="FullPayment"/>
              <Form.Check className="fs-6" onChange={(event) => setPay(event.target.value)} label="Booking Money" value="Booking Money" name="group1" type={"radio"} id="BookingMoney"/>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex flex-wrap gap-3 list-group-item">
              <Form.Check className="fs-6" checked={paymentMethod === 'Bkash' ? true : false} onChange={(event) => setPaymentMethod(event.target.value)} label="Bkash" value="Bkash" name="group2" type={"radio"} id="radio"/>
              <Form.Check className="fs-6" onChange={(event) => setPaymentMethod(event.target.value)} label="Rocket" value="Rocket" name="group2" type={"radio"} id="Rocket"/>
              <Form.Check className="fs-6" onChange={(event) => setPaymentMethod(event.target.value)} label="Nagad" value="Nagad" name="group2" type={"radio"} id="Nagad"/>
              <Form.Check className="fs-6" onChange={(event) => setPaymentMethod(event.target.value)} label="DBBL" value="DBBL" name="group2" type={"radio"} id="DBBL" />
              <Form.Check className="fs-6" onChange={(event) => setPaymentMethod(event.target.value)} label="Cash" value="Cash" name="group2" type={"radio"} id="Cash"/>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-center flex-wrap">
              {paymentMethod === "Cash" ? (
                <>
                  <span className="w-100">
                    <strong>+8801648-189538</strong>
                  </span>
                  <span className="w-100 pt-2">
                    <em>এডমিনকে কল করে নগদ অর্থের ব্যাপারে কথা বলে নিন।</em>
                  </span>
                </>
              ) : paymentMethod === "DBBL" ? (
                <>
                  <span className="w-100">
                    <strong>Dutch Bangla Bank Ltd. <br/>A/C-1011510109775</strong>
                  </span>
                  <span className="w-100 pt-2">
                    <em>AC Name: Faysal Ahmed</em>
                  </span>
                  <span className="w-100 pt-2">
                    <em>Branch: Local Office, Dhaka</em>
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <strong>{paymentMethod} Personal</strong>
                  </span>
                  <span>
                    <strong>01648189538</strong>
                  </span>
                  <span className="w-100 pt-2">
                    <em>টাকা পাঠানোর পর TNX ID অথবা যেই নম্বর থেকে টাকা পাঠিয়েছেন সেটির শেষ 4 সংখ্যা নিচের বক্সে লিখুন।</em>
                  </span>
                </>
              )}
            </ListGroup.Item>
            {paymentMethod !== "Cash" && (
              <ListGroup.Item className="p-0">
                <FloatingLabel
                  controlId="floatingSelectTnx"
                  label="Transaction ID / Number Last 4 Digit."
                >
                  <Form.Control
                    onKeyUp={(event) => setTnxId(event.target.value)}
                    type="text"
                    placeholder="Transaction ID / Number Last 4 Digit." className="bg-white" autoComplete='off'
                    required
                  />
                </FloatingLabel>
              </ListGroup.Item>
            )}
          </ListGroup>
          <Card.Body>
            <Card.Text>
              <span>Price:</span>
              <span className="float-end w-25">৳{price}</span>
            </Card.Text>
            <Card.Text>
              <span>Fee:</span>
              <span className="float-end w-25">৳{fee}</span>
            </Card.Text>
            <hr />
            <Card.Text>
              <span>Total:</span>
              <span className="float-end w-25">৳{totalPrice}</span>
            </Card.Text>
            <button className=""></button>
          </Card.Body>
          <Card.Body className="pt-0">
          {disableBooking ? null : 
            authUser === null ? (
              <>
                <Alert className="mb-2 text-center" variant='danger'>বুকিং করার জন্নে লগইন করা আবশ্যক।</Alert>
                <GoogleSignIn />
              </>
            ) : clickBookNow === true ? (
              <button
                className="btn btn-secondary w-100 fw-semibold"
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  setclickBookNow(false);
                }}
              >
                Make Changes
              </button>
            ) : (
              <button
                className="button button-main w-100"
                onClick={(event) => handleBook(event)}
              >
                Book Now
              </button>
            )}

            {errBookForm && (
              <Alert variant='danger' className="mt-4 text-center fs-6">{errBookForm}</Alert>
            )}
          </Card.Body>
        </Card>
      </form>
      
      {/* <form>
            <Card>
                <Card.Header className='d-flex justify-content-between align-center'><span>Book Now</span><span>৳{defaultPrice}</span></Card.Header>
                <Card.Body>
                    <Card.Text><span>Date:</span><span className='float-end'>10-12-2022</span></Card.Text>
                </Card.Body>
                <ListGroup className={`list-group-flush ${clickBookNow && 'no-event'}`}>
                    <ListGroup.Item className='d-flex justify-content-between align-center'>
                        <span>Adults <em>10+ Years</em></span>
                        <div className="counter d-flex align-center">
                            <button type="button" onClick={() => setAdult(adult - 1)} disabled={adult <= 1 ? true : false}><Dash /></button>
                            <p>{adult}</p>
                            <button type="button" onClick={() => setAdult(adult + 1)}><Plus /></button>
                        </div>
                    </ListGroup.Item>
                    { adult > 1 &&
                    <ListGroup.Item>
                        <Form.Check type="switch" id="couple-switch" label="Are You Couple?" defaultChecked={couple} onChange={event => setCouple(event.target.checked)} />
                    </ListGroup.Item>
                    }
                    <ListGroup.Item className='d-flex justify-content-between align-center'>
                        <span>Child <small>+{childPrice}</small> <em>2 - 10 Years</em></span>
                        <div className="counter d-flex align-center">
                            <button type="button" onClick={() => setChild(child - 1)} disabled={child <= 0 ? true : false}><Dash /></button>
                            <p>{child}</p>
                            <button type="button" onClick={() => setChild(child + 1)}><Plus /></button>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className='p-0'>
                        <FloatingLabel controlId="floatingSelectPayment" label="I will pay."  >
                        <Form.Select onChange={(event) => setPay(event.target.value)} aria-label="Floating label select payment" value={pay}>
                            <option value="Full Payment">Full Payment</option>
                            <option value="Booking Money">Booking Money</option>
                        </Form.Select>
                        </FloatingLabel>
                    </ListGroup.Item>
                    <ListGroup.Item className='p-0'>
                        <FloatingLabel controlId="floatingSelectPaymentMethod" label="Payment Metthod."  >
                        <Form.Select onChange={(event) => setPaymentMethod(event.target.value)} aria-label="Floating label select payment Method" defaultValue={paymentMethod}>
                            <option value="Bkash">Bkash</option>
                            <option value="Rocket">Rocket</option>
                            <option value="Nogod">Nogod</option>
                            <option value="DBBL">DBBL</option>
                            <option value="Cash">Cash</option>
                        </Form.Select>
                        </FloatingLabel>
                    </ListGroup.Item>
                    <ListGroup.Item className='d-flex justify-content-between align-center flex-wrap'>
                        { paymentMethod === 'Cash' ? 
                            <>
                                <span className="w-100"><strong>01761246998</strong></span>
                                <span className="w-100 pt-2"><em>Please Call Admin Confirm About It.</em></span>
                            </>
                            : paymentMethod === 'DBBL' ?
                            <>
                                <span className="w-100"><strong>70174139920</strong></span>
                                <span className="w-100 pt-2"><em>AC Name: Grihotagi</em></span>
                                <span className="w-100 pt-2"><em>Branch: Dhaka</em></span>
                            </>
                            :
                            <>
                                <span><strong>{paymentMethod} Personal</strong></span>
                                <span><strong>01761246998</strong></span>
                                <span className="w-100 pt-2"><em>After Send Money Get The TNX ID And Send It To Us.</em></span>
                            </>
                        }
                    </ListGroup.Item>
                    { paymentMethod !== 'Cash' &&
                    <ListGroup.Item className='p-0'>
                        <FloatingLabel controlId="floatingSelectTnx" label="Transaction ID."  >
                            <Form.Control onKeyUp={event => setTnxId(event.target.value)} type="text" placeholder="Transaction ID" required />
                        </FloatingLabel>
                    </ListGroup.Item> }
                </ListGroup>
                <Card.Body>
                    <Card.Text><span>Price:</span><span className='float-end w-25'>৳{price}</span></Card.Text>
                    <Card.Text><span>Fee:</span><span className='float-end w-25'>৳{fee}</span></Card.Text>
                    <hr/>
                    <Card.Text><span>Total:</span><span className='float-end w-25'>৳{totalPrice}</span></Card.Text>
                    <button className=''></button>
                </Card.Body>
                <Card.Body className='pt-0'>
                    {authUser === null ?
                        <>
                            <p className='mb-2' style={{color: 'rgba(var(--text-mc))'}}>Please Login For Confirm Your Booking.</p>
                            <GoogleSignIn /> 
                        </>
                    : 
                        clickBookNow === true ? <button className='btn btn-secondary w-100 fw-semibold' type='button' onClick={(event) => {event.preventDefault(); setclickBookNow(false)}}>Make Changes</button>
                    : 
                        <button className='button button-main w-100' onClick={(event) => handleBook(event)}>Book Now</button>
                    }
                    {errBookForm && <p className='mt-4 text-center text-danger fw-bold'>{errBookForm}</p>}
                </Card.Body>
            </Card>
            </form> */}
    </div>
  );
};

export default EventBook;

import React from "react";
import { Helmet } from "react-helmet-async";
import About from "../../component/About/About";
import HomeBlogs from "../../component/Blogs/HomeBlogs";
import Events from "../../component/Events/Events";
import Hero from "../../component/Hero/Hero";
import Motivate from "../../component/Motivet/Motivate";
import Reviews from "../../component/Reviews/Reviews";

const Home = () => {
  return (
    <>
      <Helmet>
          <title>Grihotagi | Poth Chene Pother Bak</title>
      </Helmet>
      <Hero />
      <Events />
      <About />
      <Motivate />
      <Reviews />
      <HomeBlogs />
    </>
  );
};

export default Home;

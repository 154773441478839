import React from 'react';
import { Container, Pagination, Row } from 'react-bootstrap';
import useStore from '../../GlobalProvider/useStore';
import InnerBanner from '../../component/InnerBanner/InnerBanner';
import BlogPost from '../../component/BlogPost/BlogPost';
import { Helmet } from 'react-helmet-async';

const BlogPage = () => {
    const {allBlogs} = useStore();
    const active = 2;
    const items = [];
    for (let number = 1; number <= 5; number++) {
        items.push(
            <Pagination.Item  key={number} active={number === active} href={`${process.env.PUBLIC_URL}/blog/pages/${number}`}>{number}</Pagination.Item>,
        );
    }
    return (
        <>
            <Helmet>
                <title>News and Blogs | Grihotagi</title>
            </Helmet>
            <InnerBanner title='ভ্রমণ গল্প ও আলোচনা' desc='মন খুলে লিখেতে আপনার আপনার ভ্রমণের গল্পকাহিনী।  ভ্রমণে অনেক  মজার ও বিপদজনক স্মৃতি জমা হয়।  আপনার গল্প কারো খুশির খোরাক হবে আবার কারো শিক্ষামূলক।' />
            <section className="all-blog-section section-padding">
                <Container>
                    <Row className='mb-5' style={{rowGap: '25px'}}>
                        {
                            allBlogs?.filter(blog => blog.status === "approve").slice(0, allBlogs?.length).map((blog,i)=><BlogPost key={i} blog={blog}/>)
                        }
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default BlogPage;
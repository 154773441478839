import React, { useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ParallaxBanner, ParallaxBannerLayer, ParallaxProvider } from 'react-scroll-parallax';
import bgImage from '../../assets/motivet-bg.webp';
import './Motivate.css';

const Motivate = () => {
    const peralaxStyle = {
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
            <Modal.Title className='fs-6'>মাত্র ১২৫০ টাকায় সোনাইছড়ি ঝরনা ও বাঁশবাড়িয়া - গৃহত্যাগী</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe style={{width: '100%', height: '100%', aspectRatio: '4/2'}} width="560" height="315" src="https://www.youtube.com/embed/0KZiqxFEKC4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Modal.Body>
            <Modal.Footer>
                <Link to={`/contact-us/`} className='button button-main'>Contuct Us</Link>
                <Link to={`/events/`} className='button ml-3'>Explore Events</Link>
            </Modal.Footer>
        </Modal>
        <ParallaxProvider>
            <ParallaxBanner className='motivate-Peralax'>
                <ParallaxBannerLayer image={bgImage} speed={20} className='peralax-motivet-image' />
                <ParallaxBannerLayer style={peralaxStyle} speed={10}>
                    <Container>
                        <Row>
                            <Col lg={{ span: 5, offset: 7 }} className='text-center text-md-start'>
                                <h2 className='script-heading'>পথ চেনে পথের বাঁক</h2>
                                <p className='mob-white'>প্রকৃতি এখানে রোজ সকাল থেকে সন্ধ্যে অবধি তার পাখা মেলে ঘুরে বেড়ায়। রূপ বৈচিত্রের অপরূপ আমাদের সোনার বাংলাদেশ। ‘গৃহত্যাগী’ টুর এন্ড ট্র্যাভেল এজেন্সি বাংলাদেশের ট্র্যাভেল এজেন্সিদের মাঝে অন্যতম। বাংলাদেশকে বিশ্ববাসীর কাছে তুলে ধরার লক্ষ্যে দেশের আনাচে কানাচে ঘুরে দেখার জন্য গৃহত্যাগীর আবির্ভাব। ঘুরবো দেশ-বিদেশ, জানবো দেশ-বিদেশ। তাই তো বলা হয় ‘জন্ম যেখানেই হোক না কেন,পদচালন হওয়া চাই সবখানে।</p>
                                <div onClick={handleShow} className="play-toggle mt-5">
                                    <div className="play-circle">
                                        <i className='play-icon'> </i>
                                    </div>
                                    <span><strong>ভিডিওটি দেখুন</strong></span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </ParallaxBannerLayer>
            </ParallaxBanner>
        </ParallaxProvider>
        </>
    );
};

export default Motivate;
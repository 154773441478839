import React from 'react';
import './Global.css';

const SectionHeading = (props) => {
    return (
        <div className='section-heading text-center mb-5'>
            {props.topTitle && <h3 className='script-heading'>{props.topTitle}</h3>}
            {props.title && <h2>{props.title}</h2>}
            {props.desc && <p>{props.desc}</p>}
        </div>
    );
};

export default SectionHeading;
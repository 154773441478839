import React, { useState } from "react";
import { useRef } from "react";
import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { useForm } from "react-hook-form";
import useStore from "../../GlobalProvider/useStore";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
const CreateNewPost = () => {
  const { register, handleSubmit } = useForm();
  const [uploadedImage,setUploadedImage] = useState()
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadedImage(e.target.files[0]);
    }
  };
  const {authUser,admin, callBlogs, setCallllBlogs} = useStore()
  const {name, email, picture} = jwt_decode(authUser);
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;
  const editorRef = useRef(null);
  const formRef = useRef(null);
  const log = (data) => {
    if (editorRef.current) {
      return data.texts = (editorRef.current.getContent());
    }
  };
  const onsubmit = data =>{
    log(data)
    data.author = name
    data.date = currentDate
    data.author_img = picture
    data.role = admin?.admin ? 'admin' : 'user'
    data.email = email
    let formData = new FormData()
    formData.append('blog_img',uploadedImage)
    delete data.blog_img;
    formData.append('data',JSON.stringify(data))
    const createTost = toast.loading('Please Wait...', {
      position: "bottom-right",
    });
    fetch(`${process.env.REACT_APP_BASE_URL}/blog`, {
        method: "POST",
        body: formData,
      }).then(res => res.json())
      .then(data => {
        if (data.code === 200) {
          toast.update(createTost, {
            render: 'Blog Posted! Waiting for Admin Approval.', 
            type: "success",
            isLoading: false,
            closeOnClick: true,
            autoClose: 5000
          });
          setCallllBlogs(callBlogs);
          formRef.current.reset();
          setUploadedImage(null);
        }
      }).catch((error) => {
        const errorMessage = error.message;
        toast.update(createTost, {
          render: errorMessage, 
          type: "error",
          isLoading: false,
          closeOnClick: true
        });
      });
  }
  return (
    <div>
      <Card className="shadow border-top-0 rounded-0 shadow-sm">
        <Card.Body className="p-4">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="mb-4">Create New Post.</h2>
            <span>{currentDate}</span>
          </div>
          <form  ref={formRef} onSubmit={handleSubmit(onsubmit)}>
          <Row className="mt-4 mb-4">
            <Col md={6}>
              <FloatingLabel
                controlId="floatingTitle"
                label="Title"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  {...register("title")}
                  placeholder="Title"
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md={6} className='d-flex gap-2'>
              {
                uploadedImage && <img src={URL.createObjectURL(uploadedImage)} style={{height: '56px', objectFit: 'contain'}} alt='My blog img'/>
              }
              <FloatingLabel
                controlId="floatingTitle"
                label="Blog Image"
                className="mb-3 w-100"
              >
                <Form.Control
                  type="file"
                  onChange={imageChange}
                  // {...register("blog_img")}
                  placeholder="blog image"
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Editor
            apiKey={process.env.REACT_APP_EDITOR_KEY}
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue=""
            init={{
              height: 300,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor media | alignleft aligncenter " +
                "alignright bullist numlist outdent indent removeformat",
              content_style:
                "body { font-family: -apple-system, BlinkMacSystemFont, Spectral, Georgia, serif; font-size:16px }",
            }}
            required
          />
          <button className="button button-main mt-4">Post Now</button>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CreateNewPost;
import React from "react";
import 'react-toastify/dist/ReactToastify.min.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminRoute from "./AdminRoute/AdminRoute";
import Footer from "./component/Footer/Footer";
import Scrolltotop from "./component/Global/ScrollToTop";
import Header from "./component/Header/Header";
import AboutPage from "./Pages/About/AboutPage/AboutPage";
import Admin from "./Pages/Admin/Admin";
import BlogPage from "./Pages/BlogPage/BlogPage";
import ContactPage from "./Pages/Contact/ContactPage/ContactPage";
import Dashboard from "./Pages/Dashboard/Dashboard";
import SingleEventPage from "./Pages/Event/SingleEventPage";
import EventsPage from "./Pages/Events/EventsPage";
import Home from "./Pages/Home/Home";
import Hotels from "./Pages/Hotels/Hotels";
import SigninPage from "./Pages/SignIn/SigninPage";
import GlobalProvider from "./GlobalProvider/GlobalProvider";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import TourDetails from "./Pages/TourDetails/TourDetails";
import AdminBlogDetails from "./Pages/AdminBlogDetails/AdminBlogDetails";
import UserBlogDetails from "./Pages/UserBlogDetails/UserBlogDetails";
import Terms from "./Pages/Terms/Terms";
import Privacy from "./Pages/Privacy/Privacy";
import HotelDetails from "./Pages/HotelDetails/HotelDetails";
import { ToastContainer } from "react-toastify";
const App = () => {
  return (
    <>
        <GlobalProvider>
          <BrowserRouter>
            <Header />
            <Scrolltotop />
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="events" element={<EventsPage />}></Route>
              {/* <Route path="events/single-event/:id" element={<PrivateRoute><SingleEventPage /></PrivateRoute>}/> */}
              <Route path="events/single-event/:id" element={<SingleEventPage />}/>
              <Route path="about-us" element={<AboutPage />}></Route>
              <Route path="blog/:id" element={<UserBlogDetails />}></Route>
              <Route path="blog" element={<BlogPage />}></Route>
              <Route path="contact-us" element={<ContactPage />}></Route>
              <Route path="login" element={<SigninPage />}></Route>
              <Route path="dashboard" element={<Dashboard />}></Route>
              <Route path="admin/tour-details/:id" element={<TourDetails/>}/>
              <Route path="admin/blogUpdate/:id" element={<PrivateRoute><AdminBlogDetails/></PrivateRoute>}/>
              <Route path="admin" element={<AdminRoute><Admin /></AdminRoute>}></Route>
              <Route path="hotels" element={<Hotels />}></Route>
              <Route path="terms-and-conditions" element={<Terms />}></Route>
              <Route path="privacy-policy" element={<Privacy />}></Route>
              <Route path="hotels/:id" element={<HotelDetails/>}></Route>
              <Route path="*" element={<h1>Not Found</h1>} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </GlobalProvider>
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick={true} rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark"/>
    </>
  );
};

export default App;

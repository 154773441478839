import React, { createContext } from "react";
import useProvider from "./useProvider";

export const AuthContext = createContext(null);
const GlobalProvider = ({ children }) => {
  const allContent = useProvider();
  return (
    <AuthContext.Provider value={allContent}>{children}</AuthContext.Provider>
  );
};

export default GlobalProvider;
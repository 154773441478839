import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Briefcase, Compass, Umbrella } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import aboutImage from '../../assets/about.webp';
import './About.css';

const About = () => {
    return (
        <section className='about-us section-padding'>
            <Container>
                <Row className='align-center'>
                    <Col md={{ order: 'last', span: 7 }} className='about-right text-center text-md-start'>
                        <img src={aboutImage} alt="About Grihotagi" className='about-image' />
                    </Col>
                    <Col md={{ order: 'first', span: 5 }} className='about-content text-center text-md-start'>
                        <h2 className='grihotagi-name mb-3'><span>গৃহত্যাগী</span> পথ চেনে পথের বাঁক</h2>
                        <p>আপনার দেশকে জানা শুরু হোক ’গৃহত্যাগী’-র সাথে। দেশের প্রতিটি ইঞ্চি ঘুরে বেড়ানোর থেকেই আমাদের পথ চলার শুরু। ঘুরতে ভালোবাসা মানুষদের জন্যই আমাদের আয়োজন। যার শুরুটা হয় ২০১৯ সালের ১৬ এপ্রিল। <br /> <br />শুরু থেকেই আমরা সাধ্যের মধ্যে বাজেট ঘোষণা করে সুন্দর ভ্রমণ নিশ্চিত করি। যারা ধারাবাহিকতা আমরা এখনো বজায় রাখছি।</p>
                        <div className='about-item mt-5 mb-5'>
                            <div className='single-about-item d-flex mb-3 align-center flex-column flex-md-row'>
                                <div className='flex-shrink-0 icon-wraper'>
                                    <Compass color="#FFFFFF" size={25} />
                                </div>
                                <div className="flex-grow-1 ms-md-3 ms-0 mt-2 mt-md-0">
                                    <h5 className="mt-0 fontb fw-normal">ট্যুর গাইড</h5>
                                    <p className='fst-italic fs-6'>আমাদের জন্য নিজস্ব ও অভিজ্ঞ গাইড সার্ভিস</p>
                                </div>
                            </div>
                            <div className='single-about-item d-flex mb-3 align-center flex-column flex-md-row'>
                                <div className='flex-shrink-0 icon-wraper'>
                                    <Briefcase color="#FFFFFF" size={25} />
                                </div>
                                <div className="flex-grow-1 ms-md-3 ms-0 mt-2 mt-md-0">
                                    <h5 className="mt-0 fontb fw-normal">ফ্রেন্ডলি বাজেট</h5>
                                    <p className='fst-italic fs-6'>সার্ভিস ভিত্তি করে আমরা বাজেট ঘোষণা করি। বাজেটের মধ্যে সর্বোচ্চ সার্ভিস দেয়ার চেষ্টা সবসময়</p>
                                </div>
                            </div>
                            <div className='single-about-item d-flex align-center flex-column flex-md-row'>
                                <div className='flex-shrink-0 icon-wraper'>
                                    <Umbrella color="#FFFFFF" size={25} />
                                </div>
                                <div className="flex-grow-1 ms-md-3 ms-0 mt-2 mt-md-0">
                                    <h5 className="mt-0 fontb fw-normal">নিরাপত্তা</h5>
                                    <p className='fst-italic fs-6'>আমাদের অভিজ্ঞ গাইড সবসময়ই আপনার নিরাপত্তায় সচেষ্ট থাকেন।</p>
                                </div>
                            </div>
                        </div>
                        <Link to="/events/" className='button button-main'>ইভেন্টগুলি দেখুন</Link>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;
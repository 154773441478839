import React, { useRef, useState } from "react";
import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
const AddHotel = () => {
  const { register, handleSubmit } = useForm();
  const [uploadedImage, setUploadedImage] = useState();
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadedImage(e.target.files[0]);
    }
  };
  const formRef = useRef(null);
  const editorRef = useRef(null);
  const log = (data) => {
    if (editorRef.current) {
      return (data.title = editorRef.current.getContent());
    }
  };
  const onsubmit = (data) => {
    log(data);
    let formData = new FormData();
    formData.append("img", uploadedImage);
    delete data.img;
    formData.append("data", JSON.stringify(data));
    const createTost = toast.loading('Please Wait...', {
      position: "bottom-right",
    });
    fetch(`${process.env.REACT_APP_BASE_URL}/hotel`, {
      method: "POST",
      body: formData,
    }).then((res) => res.json()).then((data) => {
      if (data.code === 200) {
        toast.update(createTost, {
          render: "Hotel Added!", 
          type: "success",
          isLoading: false,
          closeOnClick: true,
          autoClose: 5000
        });
        formRef.current.reset();
        setUploadedImage(null);
      }
    }).catch((error) => {
      const errorMessage = error.message;
      toast.update(createTost, {
        render: errorMessage, 
        type: "error",
        isLoading: false,
        closeOnClick: true
      });
    });
  };
  return (
    <Card className='shadow border-top-0 rounded-0 shadow-sm'>
      <Card.Body>
        <form ref={formRef} onSubmit={handleSubmit(onsubmit)}>
          <Row className="mt-4 mb-4">
            <Col md={6}>
              <FloatingLabel
                controlId="floatingTitle"
                label="Hotel name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  {...register("hotel_name")}
                  placeholder="Hotel name"
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              {uploadedImage && (
                <img src={URL.createObjectURL(uploadedImage)} alt="" />
              )}
              <FloatingLabel
                controlId="floatingTitle"
                label="hotel img"
                className="mb-3"
              >
                <Form.Control
                  type="file"
                  onChange={imageChange}
                  //   {...register("img")}
                  placeholder="hotel image"
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Editor
            apiKey={process.env.REACT_APP_EDITOR_KEY}
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue=""
            init={{
              height: 300,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor media | alignleft aligncenter " +
                "alignright bullist numlist outdent indent removeformat",
              content_style:
                "body { font-family: -apple-system, BlinkMacSystemFont, Spectral, Georgia, serif; font-size:16px }",
            }}
          />
          <button className="button button-main mt-4">Add Hotel</button>
        </form>
      </Card.Body>
    </Card>
  );
};

export default AddHotel;
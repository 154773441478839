import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import GoogleSignIn from './GoogleSignIn';
import './Signin.css';
// import { UserContext } from '../../Context';
import useStore from '../../GlobalProvider/useProvider';
import { Helmet } from 'react-helmet-async';
// import FacebookSignIn from './FacebookSignIn';

const SigninPage = () => {
    // const [authUser] = useContext(UserContext);
    const {authUser} = useStore()
    return (
        <>
            <Helmet>
                <title>Signin | Grihotagi</title>
            </Helmet>
            <section className="signin-page">
                { authUser && <Navigate to="/dashboard" replace={true} />}
                <Container fluid>
                    <Row className='align-center'>
                        <Col md={8}>
                            <div className="signin-page-left p-5 text-center">
                                <h1 className='mb-4'>Sign In Here!</h1>
                                <GoogleSignIn profile={true} />
                                {/* <FacebookSignIn/> */}
                            </div>
                        </Col>
                        <Col md={4} className='login-page-right d-none d-md-block'></Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default SigninPage;
import React from "react";
import { Google } from "react-bootstrap-icons";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { firebaseConfig } from "../../firebase.config";
import { useState } from "react";
import useStore from "../../GlobalProvider/useStore";
import { useLocation, useNavigate } from "react-router-dom";

const app = initializeApp(firebaseConfig);

const GoogleSignIn = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  let redirect_url = location.state?.from || location || "/";
  const { setAuthUser, setUser } = useStore();
  const provider = new GoogleAuthProvider();
  const auth = getAuth(app);
  const [err, setErr] = useState("");

  if(props.profile){
    redirect_url = "/dashboard";
  }
  const handleLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        sessionStorage.setItem("userToken", user.accessToken);
        setAuthUser(user.accessToken);
        if (user) {
          saveUser(user);
          setUser(user);
        }
        navigate(redirect_url);
      })
      .catch((error) => {
        const errorMessage = error.message;
        setErr(errorMessage);
      });
  };
  const saveUser = (user) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(user),
    });
  };
  return (
    <>
      <button onClick={handleLogin} type="button" className="login-btn">
        <Google size={18} /> Sign in with Google
      </button>
      {err && <p>{err}</p>}
    </>
  );
};

export default GoogleSignIn;
